import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HelperService } from '../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(
    private helperService: HelperService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate() {
    if (this.authService.isLogged()) {
      const clienteAdmin = this.authService.getUsuario()?.cliente?.admin;
      if (clienteAdmin) {
        return true;
      } else {
        this.helperService.notifWarn('Permiso de cliente denegado');
        return this.router.parseUrl('/login');
      }
    } else {
      this.helperService.notifWarn('Debe iniciar sesión');
      return this.router.parseUrl('/login');
    }
  }

  canActivateChild() {
    if (this.authService.isLogged()) {
      const clienteAdmin = this.authService.getUsuario()?.cliente?.admin;
      if (clienteAdmin) {
        return true;
      } else {
        this.helperService.notifWarn('Permiso de cliente denegado');
        return this.router.parseUrl('/login');
      }
    } else {
      this.helperService.notifWarn('Debe iniciar sesión');
      return this.router.parseUrl('/login');
    }
  }
}
