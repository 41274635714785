import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ICliente,
  ICrearControl,
  IFilter,
  IQueryParam,
  ISirena,
  ISocketMessage,
  IVecino,
} from 'modelos/src';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
} from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { WebSocketService } from '../../../auxiliares/servicios/websocket';
import { ControlesService } from '../controles.service';

@Component({
  selector: 'app-crear-controles',
  templateUrl: './crear-controles.component.html',
  styleUrls: ['./crear-controles.component.scss'],
})
export class CrearControlesComponent implements OnInit, OnDestroy {
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;
  hide = true;

  clientes: ICliente[] = [];

  vecinos: IVecino[] = [];
  vecinos$?: Observable<IVecino[]>;
  inputVecinos$ = new Subject<string>();

  sirenas: ISirena[] = [];
  sirenas$?: Observable<ISirena[]>;
  inputSirenas$ = new Subject<string>();

  private wsSubscription?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearControlesComponent>,
    private service: ControlesService,
    private helper: HelperService,
    private listadosService: ListadosService,
    private webSocketService: WebSocketService,
  ) {}

  private crearFormulario(): void {
    this.title = 'Crear Control';
    this.formulario = this.fb.group({
      chipIdSirena: [],
      etiqueta: [],
      chipId: [null, [Validators.required, Validators.minLength(4)]],
      idCliente: [],
      idVecino: [],
      incrementar: [false],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getData(): ICrearControl {
    const data: ICrearControl = {
      chipId: this.formulario?.get('chipId')?.value,
      etiqueta: this.formulario?.get('etiqueta')?.value,
    };
    return data;
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      await this.service.crear(data).pipe(take(1)).toPromise();
      this.helper.notifSuccess('Creado correctamente');
      //
      this.formulario?.patchValue({ chipId: null });
      this.formulario?.patchValue({ idVecino: null });
      // Incremento de etiqueta como string
      const incrementar = this.formulario?.get('incrementar')?.value;
      if (data.etiqueta && incrementar) {
        const len = data.etiqueta?.length;
        const etNum = +data.etiqueta + 1;
        let etiqueta = `0000000000000${etNum}`;
        etiqueta = etiqueta.substring(etiqueta.length - len);
        this.formulario?.patchValue({ etiqueta });
      } else {
        this.formulario?.patchValue({ etiqueta: null });
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  // Listados

  private crearFiltroSirena(search: string): IQueryParam {
    const filtro: IFilter<ISirena> = {};
    if (search) {
      filtro.$or = [
        { chipId: { $regex: search, $options: 'i' } },
        { direccionGps: { $regex: search, $options: 'i' } },
        { direccionManual: { $regex: search, $options: 'i' } },
      ];
    }
    const query: IQueryParam = {
      filter: JSON.stringify(filtro),
      limit: 10,
      select: 'chipId direccionGps direccionManual',
    };
    return query;
  }
  onSearchSirenas() {
    this.sirenas$ = of([]);
    this.inputSirenas$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) =>
          this.listadosService.listarSirenas$(this.crearFiltroSirena(term)),
        ),
      )
      .subscribe((data) => {
        console.log('sirenas encontradas', data);
        this.sirenas = data;
        this.sirenas$ = of(data);
      });
  }

  // ################################################################################
  // ################################# SUSCRIBE #####################################
  // ################################################################################

  private suscribeWsUpdates() {
    this.wsSubscription = this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }

  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('controlnoconocido')) {
      const body: {
        chipIdControl: string;
        chipIdSirena: string;
        etiqueta: string;
      } = message.body as {
        chipIdControl: string;
        chipIdSirena: string;
        etiqueta: string;
      };
      const sirenaElegida = this.formulario?.get('chipIdSirena')?.value;
      const etiqueta = body.etiqueta;
      const chipId = body.chipIdControl;
      if (sirenaElegida === body.chipIdSirena) {
        if (etiqueta) {
          this.helper.notifWarn(
            `Control chipId ${chipId} cargado con la etiqueta ${etiqueta}`,
          );
        } else {
          this.formulario?.patchValue({ chipId });
        }
      }
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.crearFormulario();
    this.suscribeWsUpdates();
    this.onSearchSirenas();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.wsSubscription?.unsubscribe();
  }
}
