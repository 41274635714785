<div class="row-flex-start" style="gap: 8px; padding: 0 0.2em 0 0.2em">
  <!-- Loading  -->
  @if (loading) {
    <div class="row-center" style="width: 100%">
      <mat-spinner color="accent" [diameter]="35"></mat-spinner>
    </div>
  } @else {
    <!-- Total de Sirenas -->
    <div class="column-center">
      <span>Sirenas</span>
      <span>{{ sirenas }}</span>
    </div>

    <!-- Input dirección -->
    <app-autocomplete-direccion
      (direccionChange)="selectDireccion($event)"
      style="width: 100%"
    ></app-autocomplete-direccion>

    <!-- Filtros -->
    <!-- Select Cliente -->
    <ng-select
      [items]="clientes"
      [(ngModel)]="clientesSeleccionados"
      [hideSelected]="true"
      placeholder="Clientes"
      [searchable]="true"
      bindLabel="nombre"
      bindValue="_id"
      [multiple]="true"
      (change)="changeCliente($event)"
      style="width: 100%"
    >
    </ng-select>

    <!-- Select Activa -->
    <ng-select
      [items]="estados"
      [(ngModel)]="estadoSeleccionado"
      [hideSelected]="true"
      placeholder="Estado"
      bindLabel="nombre"
      bindValue="value"
      (change)="changeEstado($event)"
      style="width: 100%"
    >
    </ng-select>

    <div class="column-center" style="gap: 8px; width: 15%">
      <mat-slide-toggle
        [(ngModel)]="mostrarSirenas"
        (change)="changeMostrarSirenas($event)"
        >Sirenas</mat-slide-toggle
      >
      <mat-slide-toggle
        [(ngModel)]="mostrarClientes"
        (change)="changeMostrarClientes($event)"
        >Clientes</mat-slide-toggle
      >
    </div>
  }
</div>
