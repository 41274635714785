import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrearEditarPorticoComponent } from './crear-editar-portico/crear-editar-portico.component';
import { ListarPorticosComponent } from './listar-porticos/listar-porticos.component';
import { ListadoLecturasPorticoComponent } from './listado-lecturas-portico/listado-lecturas-portico.component';
import { ListadoLecturasPorticosComponent } from './listado-lecturas-porticos/listado-lecturas-porticos.component';

const routes: Routes = [
  { path: '', component: ListarPorticosComponent },
  { path: 'crear', component: CrearEditarPorticoComponent, canActivate: [] },
  {
    path: 'editar/:id',
    component: CrearEditarPorticoComponent,
    canActivate: [],
  },
  {
    path: 'lecturas/:id',
    component: ListadoLecturasPorticoComponent,
    canActivate: [],
  },
  {
    path: 'lecturas',
    component: ListadoLecturasPorticosComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
// eslint-disable-next-line prettier/prettier
export class PorticosRoutingModule {}
