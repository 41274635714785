import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ICoordenadas } from 'modelos/src';
import { MarkerLocationService } from '../marker-location.service';
import { HelperService } from '../../servicios/helper.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  take,
} from 'rxjs/operators';
import { firstValueFrom, fromEvent } from 'rxjs';

@Component({
  selector: 'app-marker-location',
  templateUrl: './marker-location.component.html',
  styleUrls: ['./marker-location.component.scss'],
})
export class MarkerLocationComponent implements OnInit {
  @ViewChild('inputDireccion') inputDireccion?: ElementRef;
  public loading = false;
  public coordenadasDispositivo?: ICoordenadas;

  @Input() coordenadas?: ICoordenadas;
  @Output() coordenadasChange = new EventEmitter<ICoordenadas>();

  public direccion?: string;
  public preddiciones?: string[];

  constructor(
    private service: MarkerLocationService,
    private helper: HelperService
  ) {}

  public async buscarDireccion() {
    this.loading = true;
    try {
      const res = await firstValueFrom(
        this.service.direcciones(this.direccion!, this.coordenadasDispositivo)
      );

      this.preddiciones = res.resultados;
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    }
    this.loading = false;
  }

  public async onClick() {
    this.loading = true;
    try {
      const res = await this.service
        .geocode(this.direccion!)
        .pipe(take(1))
        .toPromise();
      this.coordenadas = res;
      this.coordenadasChange.emit(this.coordenadas);
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    }
    this.loading = false;
  }

  private listenDirChange(): void {
    fromEvent(this.inputDireccion?.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        filter(() => !this.loading),
        debounceTime(700),
        distinctUntilChanged()
      )
      .subscribe(async (text: string) => {
        await this.buscarDireccion();
      });
  }

  ///

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.coordenadasDispositivo = await this.helper.getCurrentPosition();
    this.listenDirChange();
    this.loading = false;
  }
}
