<div class="table-container mat-elevation-z2 mx-1 mt-4">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[
        sinCliente,
        cliente,
        activa,
        online,
        sirenaEncendida,
        reflectorEncendido,
        senal,
        errorSd,
        errorActualizacion,
        firmware,
        tipo,
        actualizando,
        mongo
      ]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton XLS -->
    <button mat-fab matTooltip="Exportar" color="primary" (click)="exportar()">
      XLS
    </button>
    <!-- Boton Crear -->
    <button
      mat-fab
      matTooltip="Crear"
      color="primary"
      [routerLink]="'importar'"
    >
      <mat-icon>add</mat-icon>
    </button>
    <!-- Boton Importar Chinas -->
    <button
      mat-fab
      matTooltip="Importar/Crear Chinas"
      color="primary"
      (click)="importarChinas()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Paginacion -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
    [pageIndex]="queryParams.page"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna chipId  -->
    <ng-container matColumnDef="chipId">
      <mat-header-cell *matHeaderCellDef [mat-sort-header]="'chipId'">
        Chip ID
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Chip ID:</span>
        <div>
          <table>
            <tr>
              <td>
                <mat-icon
                  *ngIf="row.activa"
                  [style.color]="'green'"
                  matTooltip="Activa"
                  >check</mat-icon
                >
                <mat-icon
                  *ngIf="!row.activa"
                  [style.color]="row.actualizando ? 'yellow' : 'red'"
                  matTooltip="Inactiva"
                  >clear</mat-icon
                >
              </td>
              <td>
                <div>
                  <p style="margin: 0">{{ row.chipId }}</p>
                  <p style="margin: 0">{{ row.datosHw?.numeroNac }}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna wakeup  -->
    <ng-container matColumnDef="wakeup">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Wakeup
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Wakeup:</span>
        {{ helper.msToTime(row.wakeup * 1000) }}
      </mat-cell>
    </ng-container>

    <!-- Columna sim  -->
    <ng-container matColumnDef="rssi">
      <mat-header-cell *matHeaderCellDef mat-sort-header> SIM </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">SIM:</span>

        <span
          [tooltip]="$any(HtmlContent)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon [style.color]="helper.rssiToText(row.rssi).background">
              info_outline
            </mat-icon>
            <span style="margin-left: 0.25em">{{ row.sim?.operador }}</span>
          </div>
        </span>
        <ng-template #HtmlContent>
          <div style="text-align: left">
            <!-- señal -->
            <tr>
              <th style="padding-bottom: 5px; padding-top: 5px">Señal:</th>
              <th style="padding-bottom: 5px">
                <span
                  [style.background]="helper.rssiToText(row.rssi).background"
                  [style.color]="
                    helper.invertColor(helper.rssiToText(row.rssi).background)
                  "
                  style="padding: 0.2em; border-radius: 15px"
                  [matTooltip]="row.rssi"
                >
                  {{ helper.rssiToText(row.rssi).label }}
                </span>
              </th>
            </tr>
            <!-- ICCID -->
            <tr>
              <th style="padding-bottom: 5px">ICCID:</th>
              <th style="padding-bottom: 5px">{{ row.iccidSim }}</th>
            </tr>
            <!-- IMSI -->
            <tr>
              <th style="padding-bottom: 5px">IMSI:</th>
              <th style="padding-bottom: 5px">{{ row.sim?.imsi }}</th>
            </tr>
            <!-- Nro. Tel -->
            <tr>
              <th style="padding-bottom: 5px">Nro. Tel:</th>
              <th style="padding-bottom: 5px">
                {{ row.sim?.numero || "Sin Configurar" }}
              </th>
            </tr>
            <!-- IMEI -->
            <tr>
              <th style="padding-bottom: 5px">IMEI:</th>
              <th style="padding-bottom: 5px">{{ row.imei }}</th>
            </tr>
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- Columna versionFirmware  -->
    <ng-container matColumnDef="versionFirmware">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Firmware
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Firmware:</span>
        {{ row.versionFirmware }}
      </mat-cell>
    </ng-container>

    <!-- Columna activa  -->
    <ng-container matColumnDef="activa">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Activa
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Activa:</span>
        <mat-icon *ngIf="row.activa" [style.color]="'green'">done</mat-icon>
        <mat-icon *ngIf="!row.activa" [style.color]="'red'">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna Estado  -->
    <ng-container matColumnDef="online">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Estado
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Estado:</span>

        <!-- ONLINE -->
        <span
          [tooltip]="$any(tooltipOnline)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <mat-icon *ngIf="row.online && !row.errorSd" [style.color]="'green'">
            wifi
          </mat-icon>
        </span>
        <ng-template #tooltipOnline>
          <div style="text-align: left">
            <h3>ONLINE</h3>
            <p>
              Desde el <strong>{{ row.fechaOnline | date: "short" }}</strong>
            </p>
            <p>{{ helper.tiempoTranscurrido(row.fechaOnline) }}</p>
          </div>
        </ng-template>

        <!-- OFFLINE -->
        <span
          [tooltip]="$any(tooltipOffline)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <mat-icon
            *ngIf="!row.online && !row.errorSd"
            [style.color]="row.actualizando ? 'yellow' : 'red'"
          >
            wifi_off
          </mat-icon>
        </span>
        <ng-template #tooltipOffline>
          <div style="text-align: left">
            <h3>OFFLINE <span *ngIf="row.actualizando"> (ACT)</span></h3>
            <p>
              Desde el <strong>{{ row.fechaOffline | date: "short" }}</strong>
            </p>
            <p>{{ helper.tiempoTranscurrido(row.fechaOffline) }}</p>
          </div>
        </ng-template>

        <!-- ERROR SD - ONLINE -->
        <span
          [tooltip]="$any(tooltipErrorSDOnline)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <mat-icon *ngIf="row.errorSd && row.online" [style.color]="'green'">
            sd_card_alert
          </mat-icon>
        </span>
        <ng-template #tooltipErrorSDOnline>
          <div style="text-align: left">
            <h3>ONLINE</h3>
            <p>
              Desde el <strong>{{ row.fechaOnline | date: "short" }}</strong>
            </p>
            <p>{{ helper.tiempoTranscurrido(row.fechaOnline) }}</p>
            <br />
            <h3>ERROR SD</h3>
            <p>
              Desde el <strong>{{ row.errorSd | date: "short" }}</strong>
            </p>
            <p>{{ helper.tiempoTranscurrido(row.errorSd) }}</p>
          </div>
        </ng-template>

        <!-- ERROR SD - OFFLINE -->
        <span
          [tooltip]="$any(tooltipErrorSDOffline)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <mat-icon
            *ngIf="row.errorSd && !row.online && !row.actualizando"
            [style.color]="'red'"
          >
            sd_card_alert
          </mat-icon>
          <mat-icon
            *ngIf="row.errorSd && !row.online && row.actualizando"
            [style.color]="'yellow'"
          >
            sd_card_alert
          </mat-icon>
        </span>
        <ng-template #tooltipErrorSDOffline>
          <div style="text-align: left">
            <h3>OFFLINE <span *ngIf="row.actualizando"> (ACT)</span></h3>
            <p>
              Desde el <strong>{{ row.fechaOffline | date: "short" }}</strong>
            </p>
            <p>{{ helper.tiempoTranscurrido(row.fechaOffline) }}</p>
            <br />
            <h3>ERROR SD</h3>
            <p>
              Desde el <strong>{{ row.errorSd | date: "short" }}</strong>
            </p>
            <p>{{ helper.tiempoTranscurrido(row.errorSd) }}</p>
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- Columna direccionGps  -->
    <ng-container matColumnDef="direccionGps">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Dirección
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Dirección:</span>
        <span [matTooltip]="row.direccionGps">
          {{ row.direccionGps?.split(",")[0] }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna cliente  -->
    <ng-container matColumnDef="idCliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        {{ row.cliente?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna luzEncendida  -->
    <ng-container matColumnDef="luzEncendida">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Reflector Encendido
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Reflector Encendido:</span>
        <mat-icon *ngIf="row.luzEncendida" [style.color]="'green'"
          >done</mat-icon
        >
        <mat-icon *ngIf="!row.luzEncendida" [style.color]="'red'"
          >clear</mat-icon
        >
      </mat-cell>
    </ng-container>

    <!-- Columna sonidoEncendido  -->
    <ng-container matColumnDef="sonidoEncendido">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Sirena Encendida
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Sirena Encendida:</span>
        <mat-icon *ngIf="row.sonidoEncendido" [style.color]="'green'"
          >done</mat-icon
        >
        <mat-icon *ngIf="!row.sonidoEncendido" [style.color]="'red'"
          >clear</mat-icon
        >
      </mat-cell>
    </ng-container>

    <!-- Columna acumuladoOnline  -->
    <ng-container matColumnDef="acumuladoOnline">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tiempo Online Acumulado
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Tiempo Online Acumulado:</span>
        <span>{{ helper.msToTime(row.acumuladoOnline * 1000) }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna fechaOnline  -->
    <ng-container matColumnDef="fechaOnline">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Online Desde
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Online Desde:</span>
        <span *ngIf="row.online">
          {{ helper.tiempoTranscurrido(row.fechaOnline) }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna fechaOffline  -->
    <ng-container matColumnDef="fechaOffline">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Offline Desde
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Offline Desde:</span>
        <span *ngIf="!row.online">
          {{ helper.tiempoTranscurrido(row.fechaOffline) }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna acumuladoOffline  -->
    <ng-container matColumnDef="acumuladoOffline">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tiempo Offline Acumulado
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Tiempo Offline Acumulado:</span>
        <span>{{ helper.msToTime(row.acumuladoOffline * 1000) }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna uptime  -->
    <ng-container matColumnDef="uptime">
      <mat-header-cell *matHeaderCellDef> Uptime </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Uptime:</span>
        <span
          *ngIf="row.acumuladoOnline && row.acumuladoOffline"
          [style.background]="
            helper.uptime(row.acumuladoOnline, row.acumuladoOffline).background
          "
          [style.color]="
            helper.uptime(row.acumuladoOnline, row.acumuladoOffline).color
          "
          style="padding: 0.5em; border-radius: 15px"
        >
          {{ helper.uptime(row.acumuladoOnline, row.acumuladoOffline).uptime }}
          %
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna Error actualizacion  -->
    <ng-container matColumnDef="errorActualizacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Error Actualización
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Error Actualización:</span>
        <mat-icon *ngIf="row.errorActualizacion" [style.color]="'red'"
          >done</mat-icon
        >
        <mat-icon *ngIf="!row.errorActualizacion" [style.color]="'green'"
          >clear</mat-icon
        >
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <!-- Detalles -->
          <button [routerLink]="[row._id]" mat-menu-item>
            <mat-icon color="primary">search</mat-icon>
            Detalles
          </button>
          <!-- Editar -->
          <button [routerLink]="['editar', row._id]" mat-menu-item>
            <mat-icon color="primary">edit</mat-icon>
            Editar
          </button>
          <!-- Borrar error -->
          <button
            *ngIf="row.errorSd"
            (click)="borrarErrorSd(row)"
            mat-menu-item
          >
            <mat-icon color="warn">sd_card_alert</mat-icon>
            Error SD
          </button>
          <!-- Actualizar Firmware -->
          <button
            *ngIf="row.online"
            (click)="actualizarFirmware(row)"
            mat-menu-item
          >
            <mat-icon color="accent">memory</mat-icon>
            Actualizar FW
          </button>
          <!-- Mantenimiento -->
          <button mat-menu-item (click)="mantenimiento(row._id)">
            <mat-icon color="primary">build</mat-icon>
            Crear Mantenimeinto
          </button>
          <!-- Reset -->
          <!-- <button
        *ngIf="row.online"
        (click)="reset(row)"
        mat-menu-item
      >
        <mat-icon color="warn">restart_alt</mat-icon>
      </button> -->
          <!-- Eliminar -->
          <button *ngIf="!row.online" (click)="eliminar(row)" mat-menu-item>
            <mat-icon color="warn">delete</mat-icon>
            Eliminar
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>

  <!-- Paginacion -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
    [pageIndex]="queryParams.page"
  ></mat-paginator>
</div>
