<google-map
  *ngIf="center && mapOptions"
  [center]="center"
  [width]="'100%'"
  [height]="height"
  [options]="mapOptions"
>
  <app-polygonos [polygonOptions]="otrosPoligonos"></app-polygonos>

  <ng-container *ngFor="let polygonOptions of polygonOptions; let i = index">
    <map-polygon
      [options]="polygonOptions"
      (polygonRightclick)="mostrarInfoWindow($event, i)"
    >
    </map-polygon>
  </ng-container>

  <map-info-window>
    <div
      style="display: flex; align-items: center"
      class="cursor-pointer"
      (click)="eliminar()"
    >
      <span>Eliminar</span>
      <mat-icon class="material-icons" color="warn">delete</mat-icon>
    </div>
  </map-info-window>
</google-map>

<div class="btn-crear">
  <button
    class="mx-2"
    type="button"
    mat-fab
    [matTooltip]="!dibujando ? 'Crear' : 'Cancelar'"
    [color]="!dibujando ? 'accent' : 'warn'"
    (click)="!dibujando ? dibujar() : cancelarDibujo()"
  >
    <mat-icon>{{ !dibujando ? "add" : "clear" }}</mat-icon>
  </button>
</div>

<div class="btn-crear">
  <button
    style="margin-right: 0.5em"
    class="mx-2"
    type="button"
    mat-fab
    matTooltip="Importar GeoJSON"
    color="accent"
    (click)="importar()"
  >
    <mat-icon>upload</mat-icon>
  </button>
</div>
