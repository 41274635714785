import { Injectable } from '@angular/core';
import { apiAdmin } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpService, IHttpRequestOptions } from './http.service';
import { HttpParams } from '@angular/common/http';
import saveAs from 'file-saver';

const api = apiAdmin;

@Injectable({
  providedIn: 'root',
})
export class HttpApiAdminService {
  constructor(private http: HttpService) {}

  public get(ruta: string, options?: IHttpRequestOptions): Observable<any> {
    return this.http.get(api + ruta, options);
  }

  public get2(ruta: string, options?: IHttpRequestOptions): Observable<any> {
    return this.http.get2(api + ruta, options);
  }

  public post(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions
  ): Observable<any> {
    return this.http.post(api + ruta, body, options);
  }

  public put(ruta: string, body: any): Observable<any> {
    return this.http.put(api + ruta, body);
  }

  public delete(ruta: string): Observable<any> {
    return this.http.delete(api + ruta);
  }

  public async getFile(
    url: string,
    filename: string,
    params?: HttpParams
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.get(url, { params, responseType: 'blob' }).subscribe(
        (response: any) => {
          try {
            const file = new File([response], filename);
            saveAs(file);
            resolve();
          } catch (error) {
            console.error(error);
            resolve();
          }
        },
        (error: any) => {
          const reader: FileReader = new FileReader();
          reader.onloadend = (e) => {
            reject(JSON.parse(reader.result as string));
          };
          reader.readAsText(error?.error);
        }
      );
    });
  }
}
