import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ICrearFirmwareVersion, IFirmwareSirena, IListado } from 'modelos/src';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { FirmwareVersionesService } from '../firmware-versiones.service';

@Component({
  selector: 'app-crear-firmware-version',
  templateUrl: './crear-firmware-version.component.html',
  styleUrls: ['./crear-firmware-version.component.scss'],
})
export class CrearFirmwareVersionComponent implements OnInit {
  formulario?: UntypedFormGroup;
  loading = false;

  firmwares: IFirmwareSirena[] = [];

  // Listado Continuo
  private datos$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearFirmwareVersionComponent>,
    private service: FirmwareVersionesService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  private crearFormulario(): void {
    this.formulario = this.fb.group({
      versionActual: [null, Validators.required],
      versionNueva: [null, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data: ICrearFirmwareVersion = {
        versionActual: this.formulario?.get('versionActual')?.value,
        versionNueva: this.formulario?.get('versionNueva')?.value,
      };
      await this.service.crear(data).pipe(take(1)).toPromise();
      this.helper.notifSuccess('Creado correctamente');
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  //

  private async listarFirmwareSirenas(): Promise<void> {
    // Query
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IFirmwareSirena>>('firmwareSirenas', {})
      .subscribe((data) => {
        this.firmwares = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de firmwareSirenas`,
          data
        );
      });
    await this.listadosService.getLastValue('firmwareSirenas', {});
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.crearFormulario();
    await this.listarFirmwareSirenas();
    this.loading = false;
  }
  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
