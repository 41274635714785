import { Injectable } from '@angular/core';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';
import { Observable } from 'rxjs';
import { ICoordenadas } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class MapaService {
  constructor(private http: HttpApiAdminService) {}

  // public geocodeDireccion(
  //   direccion: string
  // ): Observable<{ coordenadas: ICoordenadas }> {
  //   return this.http.get(`/geocode/direccion/${direccion}`);
  // }

  public geocodeDireccion(direccion: string): Observable<ICoordenadas> {
    return this.http.post(`/geocode/geocode`, { text: direccion });
  }
}
