<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<div
  *ngIf="mostrarGraficos"
  class="m-3"
  fxLayout="row wrap"
  fxLayoutAlign="space-around"
>
  <div *ngIf="chartOptionsVecinosPorCliente" fxFlex>
    <app-chart
      [(options)]="chartOptionsVecinosPorCliente"
      style="
        width: 350px;
        max-width: 90%;
        height: 400px;
        display: block;
        margin-top: 30px;
        margin: auto;
      "
    ></app-chart>
  </div>

  <div *ngIf="chartOptionsUsuariosPorCliente" fxFlex>
    <app-chart
      [(options)]="chartOptionsUsuariosPorCliente"
      style="
        width: 350px;
        max-width: 90%;
        height: 400px;
        display: block;
        margin-top: 30px;
        margin: auto;
      "
    ></app-chart>
  </div>

  <div *ngIf="chartOptionsAlertasPorCliente" fxFlex>
    <app-chart
      [(options)]="chartOptionsAlertasPorCliente"
      style="
        width: 350px;
        max-width: 90%;
        height: 400px;
        display: block;
        margin-top: 30px;
        margin: auto;
      "
    ></app-chart>
  </div>

  <div *ngIf="chartOptionsSirenasPorCliente" fxFlex>
    <app-chart
      [(options)]="chartOptionsSirenasPorCliente"
      style="
        width: 350px;
        max-width: 90%;
        height: 400px;
        display: block;
        margin-top: 30px;
        margin: auto;
      "
    ></app-chart>
  </div>

  <div *ngIf="chartOptionsPorticosPorCliente" fxFlex>
    <app-chart
      [(options)]="chartOptionsPorticosPorCliente"
      style="
        width: 350px;
        max-width: 90%;
        height: 400px;
        display: block;
        margin-top: 30px;
        margin: auto;
      "
    ></app-chart>
  </div>

  <div *ngIf="chartOptionsControlesPorCliente" fxFlex>
    <app-chart
      [(options)]="chartOptionsControlesPorCliente"
      style="
        width: 350px;
        max-width: 90%;
        height: 400px;
        display: block;
        margin-top: 30px;
        margin: auto;
      "
    ></app-chart>
  </div>
</div>

<div class="table-container mat-elevation-z2">
  <!-- Tabla -->
  <mat-table class="responsive" [dataSource]="dataSource" matSort>
    <!-- Columna cliente  -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef> Cliente </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        {{ row.cliente }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Clientes: {{ totalClientes }} </strong>
      </mat-footer-cell>
    </ng-container>

    <!-- Columna cantidadUsuarios  -->
    <ng-container matColumnDef="cantidadUsuarios">
      <mat-header-cell *matHeaderCellDef> Usuarios </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Usuarios:</span>
        {{ row.cantidadUsuarios }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Usuarios: {{ totalUsuarios }} </strong>
      </mat-footer-cell>
    </ng-container>

    <!-- Columna cantidadVecinos  -->
    <ng-container matColumnDef="cantidadVecinos">
      <mat-header-cell *matHeaderCellDef> Vecinos </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Vecinos:</span>
        {{ row.cantidadVecinos }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Vecinos: {{ totalVecinos }} </strong>
      </mat-footer-cell>
    </ng-container>

    <!-- Columna cantidadAlertas  -->
    <ng-container matColumnDef="cantidadAlertas">
      <mat-header-cell *matHeaderCellDef> Alertas </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Alertas:</span>
        {{ row.cantidadAlertas }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Alertas: {{ totalAlertas }} </strong>
      </mat-footer-cell>
    </ng-container>

    <!-- Columna cantidadSirenas  -->
    <ng-container matColumnDef="cantidadSirenas">
      <mat-header-cell *matHeaderCellDef> Sirenas </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Sirenas:</span>
        {{ row.cantidadSirenas }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Sirenas: {{ totalSirenas }} </strong>
      </mat-footer-cell>
    </ng-container>

    <!-- Columna cantidadPorticos  -->
    <ng-container matColumnDef="cantidadPorticos">
      <mat-header-cell *matHeaderCellDef> Pórticos </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Pórticos:</span>
        {{ row.cantidadPorticos }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Pórticos: {{ totalPorticos }} </strong>
      </mat-footer-cell>
    </ng-container>

    <!-- Columna cantidadControles  -->
    <ng-container matColumnDef="cantidadControles">
      <mat-header-cell *matHeaderCellDef> Controles </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Controles:</span>
        {{ row.cantidadControles }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong> Controles: {{ totalControles }} </strong>
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
    <mat-footer-row *matFooterRowDef="columnas"> </mat-footer-row>
  </mat-table>
</div>
