import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MantenimientosComponent } from './listado/mantenimientos.component';
import { DetallesMantenimientoComponent } from './detalles-mantenimiento/detalles-mantenimiento.component';

const routes: Routes = [
  { path: '', component: MantenimientosComponent },
  {
    path: 'detalles/:id',
    component: DetallesMantenimientoComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MantenimientosRoutingModule {}
