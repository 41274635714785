import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IMantenimiento,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';
import { AuthService } from '../../login/auth.service';

@Component({
  selector: 'app-listados-mantenimientos',
  templateUrl: './mantenimientos.component.html',
  styleUrls: ['./mantenimientos.component.scss'],
})
export class ListadosMantenimientosComponent implements OnInit {
  @Input() chipId?: string;

  public usuarioActual?: IUsuario;

  public loading = true;
  public columnas = ['fecha', 'tags', 'chipId', 'usuario'];
  public nombreColumnas = ['Fecha', 'Tags', 'Sirena', 'Usuario'];
  public saveColumnas = 'tabla-mantenimientos';
  public dataSource = new MatTableDataSource<IMantenimiento>([]);
  // FILTRO BUSQUEDA Y PAGINACION
  public totalCount = 0;
  private initialFilters: IFilter<IMantenimiento> = {
    chipId: this.chipId,
  };
  private populate = [
    {
      path: 'sirena',
      select: 'chipId',
    },
    {
      path: 'usuario',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify(this.initialFilters),
  };
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private listadosService: ListadosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public authService: AuthService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }

  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    const filter = {
      chipId: this.chipId,
    };
    this.queryParams.filter = JSON.stringify(filter);

    this.datos$ = this.listadosService
      .subscribe<IListado<IMantenimiento>>('mantenimientos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de mantenimientos`,
          data
        );
      });
    await this.listadosService.getLastValue('mantenimientos', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    this.usuarioActual = this.authService.getUsuario();
    await Promise.all([this.actualizar()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
