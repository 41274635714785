import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapPolygon } from '@angular/google-maps';
import { ICoordenadas } from 'modelos/src';
import { DialogService } from '../dialog/dialog.service';
import { HelperService } from '../servicios/helper.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImportarComponent } from 'src/app/entidades/clientes/crear-editar-cliente/importar/importar.component';

@Component({
  selector: 'app-polygon-draw',
  templateUrl: './polygon-draw.component.html',
  styleUrls: ['./polygon-draw.component.scss'],
})
export class PolygonDrawComponent implements OnInit {
  public dibujando = true;

  @Input() zoom: number = 10;
  @Input() height: string = '500px';

  @Input() coordenadas: ICoordenadas[][] = [];

  // Mapa
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined;
  public center?: google.maps.LatLngLiteral;
  public mapOptions?: google.maps.MapOptions;

  // Poligonos
  @Input() otrosPoligonos: google.maps.PolygonOptions[] = [];
  @ViewChildren(MapPolygon) polygonRef?: QueryList<MapPolygon>;
  public polygonOptions: google.maps.PolygonOptions[] = [];
  public selectedPoly?: number;

  // Info Window
  @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;

  // Dibujo de poligono
  public drawingManager = new google.maps.drawing.DrawingManager({
    polygonOptions: {
      editable: true,
      draggable: false,
    },
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
  });

  get polygons(): ICoordenadas[][] {
    const polygons = this.polygonRef!.toArray();
    const response: ICoordenadas[][] = [];
    for (const polygon of polygons) {
      response.push(
        polygon
          ?.getPath()
          ?.getArray()
          .map((position) => {
            return { lat: position.lat(), lng: position.lng() };
          })
      );
    }
    return response;
  }

  constructor(
    private helper: HelperService,
    private dalogService: DialogService,
    private matDialog: MatDialog
  ) {}

  public mostrarInfoWindow(
    event: google.maps.PolyMouseEvent,
    selectedPoly: number
  ) {
    this.selectedPoly = selectedPoly;
    this.infoWindow!.position = event.latLng!;
    this.infoWindow!.open();
  }

  public async eliminar() {
    const confirm = await this.dalogService.confirm(
      'Por favor confirme la acción',
      '¿Eliminar el mapa seleccionado?'
    );
    if (confirm) {
      const polygons = this.polygons;
      polygons.splice(this.selectedPoly!, 1);
      const polygonsOptions = [];
      for (const poly of polygons) {
        polygonsOptions.push({
          paths: poly,
          clickable: true,
          editable: true,
          draggable: false,
        });
      }
      this.polygonOptions = polygonsOptions;
      this.helper.notifSuccess('Mapa eliminado');
    }
    this.infoWindow?.close();
  }

  public dibujar() {
    if (this.map?.googleMap) {
      this.initDrawingManager();
      this.drawingManager.setMap(this.map?.googleMap || null);
      this.dibujando = true;
    } else {
      setTimeout(() => this.dibujar(), 50);
    }
  }

  public listenDrawEnd() {
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      async (event: any) => {
        // Polygon drawn
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const coordenadas: ICoordenadas[] = [];
          event.overlay
            .getPath()
            .getArray()
            .forEach((position: any) => {
              const pos: ICoordenadas = {
                lat: position.lat(),
                lng: position.lng(),
              };
              coordenadas.push(pos);
            });
          this.polygonOptions.push({
            draggable: false,
            editable: true,
            paths: coordenadas,
          });
          this.dibujando = false;
          event.overlay.setMap(null);
          this.drawingManager.setMap(null);
        }
      }
    );
  }

  public cancelarDibujo() {
    this.dibujando = false;
    this.drawingManager.setMap(null);
  }

  private setMapOptions() {
    this.mapOptions = {
      zoom: this.zoom,
      streetViewControl: false,
      fullscreenControl: false,
      rotateControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeId: 'roadmap',
      mapTypeControlOptions: {
        mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      // Zoom manteniendo ctrl
      gestureHandling: 'cooperative',
    };
  }

  private initDrawingManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      polygonOptions: {
        editable: true,
        draggable: false,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
    });
    this.listenDrawEnd();
  }

  private async initMap() {
    this.setMapOptions();
    if (this.coordenadas) {
      this.center = this.helper.getCentro(this.coordenadas[0]) || {
        lat: -34.603722,
        lng: -58.381592,
      };
      for (const coordenada of this.coordenadas) {
        this.polygonOptions.push({
          draggable: false,
          editable: true,
          paths: coordenada,
        });
      }
      this.dibujando = false;
    } else {
      const ubicacion = await this.helper.getCurrentPosition();
      this.center = ubicacion;
      this.dibujar();
      this.dibujando = true;
    }
  }

  // IMPORTAR

  public async importar() {
    const config: MatDialogConfig = {
      width: '1200px',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };

    const res = this.matDialog.open(ImportarComponent, config);
    const data = (await res.afterClosed().toPromise()) as ICoordenadas[][];

    if (data) {
      const coordenadas = data;
      const polygonsOptions = [];
      for (const poly of coordenadas) {
        polygonsOptions.push({
          paths: poly,
          clickable: true,
          editable: true,
          draggable: false,
        });
      }
      this.polygonOptions = polygonsOptions;
      this.coordenadas = data;
    }
  }

  ngOnInit(): void {
    this.initMap();
  }
}
