import { Component, OnInit } from '@angular/core';
import { ICliente, IListado, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../servicios/listados.service';
import { HelperService } from '../../servicios/helper.service';
import { DialogService } from '../../dialog/dialog.service';
import { ExportarService } from '../exportar.service';

@Component({
  selector: 'app-exportar',
  templateUrl: './exportar.component.html',
  styleUrls: ['./exportar.component.scss'],
})
export class ExportarComponent implements OnInit {
  public loading? = false;
  public clientes?: ICliente[];
  public cliente?: string;
  public fecha?: Date;
  public tipos = ['Sirenas', 'Eventos', 'Vecinos', 'Mantenimientos', 'Todos'];
  public tipo?: string;
  public clientes$?: Subscription;

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
    private dialog: DialogService,
    private service: ExportarService
  ) {}

  private async listar(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      sort: 'nombre',
      select: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  public async exportar() {
    if (!this.tipo) {
      this.helper.notifError('Debe seleccionar un tipo de exportación');
    }
    if (!this.cliente) {
      this.helper.notifError('Debe seleccionar un cliente');
    }

    const data = {
      cliente: this.cliente,
      fecha: this.fecha?.toISOString(),
    };

    switch (this.tipo) {
      case 'Sirenas':
        const confirm = await this.dialog.confirm(
          'Confirme la acción',
          `¿Desea importar las sirenas creadas en el mes seleccionado?`
        );
        if (data) {
          if (confirm) {
            try {
              this.loading = true;
              await this.service.sirenas(data.fecha!, data.cliente!);
            } catch (error) {
              console.error(error);
              this.helper.notifError(error);
            }
          }
        }
        break;
      case 'Eventos':
        const confirm1 = await this.dialog.confirm(
          'Confirme la acción',
          `¿Desea importar los eventos creados en el mes seleccionado?`
        );
        if (data) {
          if (confirm1) {
            try {
              this.loading = true;
              await this.service.eventos(data.fecha!, data.cliente!);
            } catch (error) {
              console.error(error);
              this.helper.notifError(error);
            }
          }
        }
        break;
      case 'Vecinos':
        const confirm2 = await this.dialog.confirm(
          'Confirme la acción',
          `¿Desea importar los vecinos creados en el mes seleccionado?`
        );
        if (data) {
          if (confirm2) {
            try {
              this.loading = true;
              await this.service.vecinos(data.fecha!, data.cliente!);
            } catch (error) {
              console.error(error);
              this.helper.notifError(error);
            }
          }
        }
        break;

      case 'Mantenimientos':
        const confirm3 = await this.dialog.confirm(
          'Confirme la acción',
          `¿Desea importar los mantenimientos creados en el mes seleccionado?`
        );
        if (data) {
          if (confirm3) {
            try {
              this.loading = true;
              await this.service.mantenimientos(data.fecha!, data.cliente!);
            } catch (error) {
              console.error(error);
              this.helper.notifError(error);
            }
          }
        }
        break;

      default:
        break;
    }
    this.loading = false;
  }

  public async exportarResumen() {
    const confirm = await this.dialog.confirm(
      'Confirme la acción',
      `¿Desea importar las sirenas creadas en el mes seleccionado?`
    );

    if (confirm) {
      try {
        this.loading = true;
        await this.service.resumen();
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }
}
