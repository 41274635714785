import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfigVecino, INuevoVecino, IUpdateConfigVecino } from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { VecinosService } from 'src/app/entidades/vecinos/vecinos.service';

@Component({
  selector: 'app-crear-editar-vecino',
  templateUrl: './crear-editar-vecino.component.html',
  styleUrls: ['./crear-editar-vecino.component.scss'],
})
export class CrearEditarVecinoComponent implements OnInit {
  @ViewChild('inputDireccion', { static: true }) inputDireccion:
    | ElementRef
    | undefined;
  public direcciones: string[] = [];
  public direccion = '';

  public formulario: UntypedFormGroup;
  public loading = false;
  public title = '';
  public reg = /^[1-9][0-9]{9}$/;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfigVecino,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarVecinoComponent>,
    private service: VecinosService,
    private helper: HelperService,
  ) {
    this.title = this.data?._id ? 'Editar Vecino' : 'Crear Vecino';
    this.formulario = this.fb.group({
      activo: [this.data?.activo || false],
      dni: [this.data?.datosPersonales?.dni || '', Validators.required],
      sexo: [this.data?.datosPersonales?.sexo, Validators.required],
      nombre: [this.data?.datosPersonales?.nombre || '', Validators.required],
      fechaNacimiento: [
        this.data?.datosPersonales?.fechaNacimiento || '',
        Validators.required,
      ],
      telefono: [
        this.data?.datosPersonales?.telefono || '',
        [Validators.required, Validators.pattern(this.reg)],
      ],
      email: [this.data?.datosPersonales?.email || '', Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getCreateData(): INuevoVecino {
    return {
      dni: this.formulario.get('dni')?.value,
      sexo: this.formulario.get('sexo')?.value,
      nombre: this.formulario.get('nombre')?.value,
      fechaNacimiento: this.formulario.get('fechaNacimiento')?.value,
      telefono: this.formulario.get('telefono')?.value,
      email: this.formulario.get('email')?.value,
      pais: '+54',
    };
  }

  private getUpdateData(): IUpdateConfigVecino {
    const data: IUpdateConfigVecino = {
      activo: this.formulario.get('activo')?.value,
      datosPersonales: {
        dni: this.formulario.get('dni')?.value,
        nombre: this.formulario.get('nombre')?.value,
        sexo: this.formulario.get('sexo')?.value,
        fechaNacimiento: this.formulario.get('fechaNacimiento')?.value,
        telefono: this.formulario.get('telefono')?.value,
        email: this.formulario.get('email')?.value,
      },
    };
    return data;
  }

  public async enviar(): Promise<void> {
    // this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editarConfig(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    // this.listenDirChange();
  }
}
