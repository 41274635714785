import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sirenas',
  templateUrl: './sirenas.component.html',
  styleUrls: ['./sirenas.component.scss'],
})
export class SirenasComponent implements OnInit {
  public loading?: boolean;

  public titulo = `Sirenas`;

  constructor() {}

  async ngOnInit(): Promise<void> {}
}
