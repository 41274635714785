import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IMantenimiento,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { MantenimientosService } from '../mantenimientos.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';
import { AuthService } from '../../login/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mantenimientos',
  templateUrl: './mantenimientos.component.html',
  styleUrls: ['./mantenimientos.component.scss'],
})
export class MantenimientosComponent implements OnInit {
  public usuarioActual?: IUsuario;

  public loading = true;
  public columnas = [
    'fecha',
    'tags',
    'chipId',
    'usuario',
    'cliente',
    'direccion',
    'acciones',
  ];
  public nombreColumnas = [
    'Fecha',
    'Tags',
    'Sirena',
    'Usuario',
    'Cliente',
    'Dirección',
    'Acciones',
  ];
  public saveColumnas = 'tabla-mantenimientos';
  public dataSource = new MatTableDataSource<IMantenimiento>([]);
  private datos: IMantenimiento[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public totalCount = 0;
  private initialFilters: IFilter<IMantenimiento> = {};
  private populate = [
    {
      path: 'sirena',
      select: 'chipId direccionGps',
    },
    {
      path: 'usuario',
      select: 'nombre',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify(this.initialFilters),
  };
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private listadosService: ListadosService,
    private service: MantenimientosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public authService: AuthService,
    private router: Router
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  // ACCIONES
  // public async crear(): Promise<void> {
  //   const config: MatDialogConfig = {
  //     width: '700px',
  //
  //   };
  //   this.matDialog.open(CrearEditarUsuarioComponent, config);
  // }
  // public async editar(data: mainType) {
  //   const config: MatDialogConfig = {
  //     data,
  //     width: '700px',
  //
  //   };
  //   this.matDialog.open(CrearEditarUsuarioComponent, config);
  // }
  // public async eliminar(dato: mainType): Promise<void> {
  //   const confirm = await this.dialogService.confirm(
  //     'Confirme la acción',
  //     `¿Eliminar el usuario ${dato.usuario}?`
  //   );
  //   if (confirm) {
  //     try {
  //       this.loading = true;
  //       await this.service.eliminar(dato._id).pipe(take(1)).toPromise();
  //       this.helper.notifSuccess('Eliminación correcta');
  //       this.loading = false;
  //     } catch (error) {
  //       console.error(error);
  //       this.helper.notifError(error);
  //       this.loading = false;
  //     }
  //   }
  // }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IMantenimiento>>('mantenimientos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de mantenimientos`,
          data
        );
      });
    await this.listadosService.getLastValue('mantenimientos', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    this.usuarioActual = this.authService.getUsuario();
    await Promise.all([this.actualizar()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
