import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent {
  title: string;
  message: string;
  inputName: string[];
  inputValue: string[];
  btnOkText: string;
  btnCancelText: string;

  constructor(
    public matDialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      title: string;
      message: string;
      inputName: string[];
      inputValue: string[];
      btnOkText: string;
      btnCancelText: string;
    },
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.inputName = this.data.inputName;
    this.inputValue = this.data.inputValue;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
  }
}
