import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Options, SeriesPieOptions } from 'highcharts';
import { IResumenClientes } from 'modelos/src';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { DashboardService } from '../dashboard.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public loading: boolean = true;

  public dataSource = new MatTableDataSource<IResumenClientes>([]);

  public totalClientes: number = 0;
  public totalVecinos: number = 0;
  public totalUsuarios: number = 0;
  public totalAlertas: number = 0;
  public totalSirenas: number = 0;
  public totalPorticos: number = 0;
  public totalControles: number = 0;

  public mostrarGraficos: boolean = false;
  public chartOptionsVecinosPorCliente?: Options;
  public chartOptionsUsuariosPorCliente?: Options;
  public chartOptionsAlertasPorCliente?: Options;
  public chartOptionsSirenasPorCliente?: Options;
  public chartOptionsPorticosPorCliente?: Options;
  public chartOptionsControlesPorCliente?: Options;

  public columnas: string[] = [
    'cliente',
    'cantidadUsuarios',
    'cantidadVecinos',
    'cantidadAlertas',
    'cantidadSirenas',
    'cantidadPorticos',
    'cantidadControles',
  ];

  constructor(
    private dashboardService: DashboardService,
    private helperService: HelperService,
  ) {}

  // Crear Graficos

  private pieChart(title: string, series: SeriesPieOptions[]) {
    const chartOptions: Options = {
      title: {
        text: title,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
            distance: -50,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4,
            },
          },
          showInLegend: false,
        },
      },
      series,
    };
    return chartOptions;
  }

  private getStringColors(strs: string[]): string[] {
    const colors: string[] = [];
    strs.forEach((str) => {
      colors.push(this.helperService.stringToColour(str));
    });
    return colors;
  }

  private createChartVecinosPorCliente(
    resumenClientes: IResumenClientes[],
  ): void {
    const title = 'Vecinos por cliente';
    const data: { name: string; y: number }[] = [];
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Vecinos',
        colors: this.getStringColors(
          resumenClientes.map((resumenCliente) => resumenCliente.cliente),
        ),
        data,
      },
    ];
    resumenClientes.forEach((resumenCliente) => {
      data.push({
        name: resumenCliente.cliente,
        y: resumenCliente.cantidadVecinos,
      });
    });
    this.chartOptionsVecinosPorCliente = this.pieChart(title, series);
  }

  private createChartUsuariosPorCliente(
    resumenClientes: IResumenClientes[],
  ): void {
    const title = 'Usuarios por cliente';
    const data: { name: string; y: number }[] = [];
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Usuarios',
        colors: this.getStringColors(
          resumenClientes.map((resumenCliente) => resumenCliente.cliente),
        ),
        data,
      },
    ];
    resumenClientes.forEach((resumenCliente) => {
      data.push({
        name: resumenCliente.cliente,
        y: resumenCliente.cantidadUsuarios,
      });
    });
    this.chartOptionsUsuariosPorCliente = this.pieChart(title, series);
  }

  private createChartAlertasPorCliente(
    resumenClientes: IResumenClientes[],
  ): void {
    const title = 'Alertas por cliente';
    const data: { name: string; y: number }[] = [];
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Alertas',
        colors: this.getStringColors(
          resumenClientes.map((resumenCliente) => resumenCliente.cliente),
        ),
        data,
      },
    ];
    resumenClientes.forEach((resumenCliente) => {
      data.push({
        name: resumenCliente.cliente,
        y: resumenCliente.cantidadAlertas,
      });
    });
    this.chartOptionsAlertasPorCliente = this.pieChart(title, series);
  }

  private createChartSirenasPorCliente(
    resumenClientes: IResumenClientes[],
  ): void {
    const title = 'Sirenas por cliente';
    const data: { name: string; y: number }[] = [];
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Sirenas',
        colors: this.getStringColors(
          resumenClientes.map((resumenCliente) => resumenCliente.cliente),
        ),
        data,
      },
    ];
    resumenClientes.forEach((resumenCliente) => {
      data.push({
        name: resumenCliente.cliente,
        y: resumenCliente.cantidadSirenas,
      });
    });
    this.chartOptionsSirenasPorCliente = this.pieChart(title, series);
  }

  private createChartPorticosPorCliente(
    resumenClientes: IResumenClientes[],
  ): void {
    const title = 'Pórticos por cliente';
    const data: { name: string; y: number }[] = [];
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Porticos',
        colors: this.getStringColors(
          resumenClientes.map((resumenCliente) => resumenCliente.cliente),
        ),
        data,
      },
    ];
    resumenClientes.forEach((resumenCliente) => {
      data.push({
        name: resumenCliente.cliente,
        y: resumenCliente.cantidadPorticos,
      });
    });
    this.chartOptionsPorticosPorCliente = this.pieChart(title, series);
  }

  private createChartControlesPorCliente(
    resumenClientes: IResumenClientes[],
  ): void {
    const title = 'Controles por cliente';
    const data: { name: string; y: number }[] = [];
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Controles',
        colors: this.getStringColors(
          resumenClientes.map((resumenCliente) => resumenCliente.cliente),
        ),
        data,
      },
    ];
    resumenClientes.forEach((resumenCliente) => {
      data.push({
        name: resumenCliente.cliente,
        y: resumenCliente.cantidadControles,
      });
    });
    this.chartOptionsControlesPorCliente = this.pieChart(title, series);
  }

  private crearGraficos(resumenClientes: IResumenClientes[]): void {
    this.createChartVecinosPorCliente(resumenClientes);
    this.createChartUsuariosPorCliente(resumenClientes);
    this.createChartAlertasPorCliente(resumenClientes);
    this.createChartSirenasPorCliente(resumenClientes);
    this.createChartPorticosPorCliente(resumenClientes);
    this.createChartControlesPorCliente(resumenClientes);
    this.mostrarGraficos = true;
  }

  // Crear tabla

  private crearTabla(resumenClientes: IResumenClientes[]): void {
    this.totalClientes = resumenClientes.length;
    this.totalAlertas = resumenClientes.reduce(
      (total, resumenCliente) => total + resumenCliente.cantidadAlertas,
      0,
    );
    this.totalUsuarios = resumenClientes.reduce(
      (total, resumenCliente) => total + resumenCliente.cantidadUsuarios,
      0,
    );
    this.totalVecinos = resumenClientes.reduce(
      (total, resumenCliente) => total + resumenCliente.cantidadVecinos,
      0,
    );
    this.totalSirenas = resumenClientes.reduce(
      (total, resumenCliente) => total + resumenCliente.cantidadSirenas,
      0,
    );
    this.totalPorticos = resumenClientes.reduce(
      (total, resumenCliente) => total + resumenCliente.cantidadPorticos,
      0,
    );
    this.totalControles = resumenClientes.reduce(
      (total, resumenCliente) => total + resumenCliente.cantidadControles,
      0,
    );
    this.dataSource.data = resumenClientes;
  }

  // Listar datos

  private async getResumenClientes(): Promise<void> {
    let resumenClientes = await firstValueFrom(
      this.dashboardService.resumenClientes(),
    );

    resumenClientes.sort((a, b) => a.cliente.localeCompare(b.cliente));
    console.log('resumenClientes', resumenClientes);
    this.crearTabla(resumenClientes);
    this.crearGraficos(resumenClientes);
  }

  async ngOnInit(): Promise<void> {
    await this.getResumenClientes();
    this.loading = false;
  }
}
