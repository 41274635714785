<div class="column-center" style="gap: 8px; width: 100%">
  <div class="isla-dato">
    <span class="isla-titulo"
      >{{ (sirena?.tipo | titlecase) || "Sirena" }}
    </span>
    <button mat-icon-button (click)="cerrar()">
      <mat-icon color="warn">close</mat-icon>
    </button>
  </div>
  <div class="isla-dato">
    <span>Chip ID</span>
    <span class="link" [routerLink]="['/', 'main', 'sirenas', sirena?._id]">
      {{ sirena?.chipId }}
    </span>
  </div>
  @if (sirena?.versionFirmware) {
    <div class="isla-dato">
      <span>Firmware</span>
      <span>{{ sirena?.versionFirmware }}</span>
    </div>
  }
  @if (sirena?.rssi) {
    <div class="isla-dato">
      <span>Señal</span>
      <span>{{ helper.rssiToText(sirena?.rssi).label }}</span>
    </div>
  }
  @if (sirena?.estado) {
    <div class="isla-dato">
      <span>Estado</span>
      <span>{{ sirena?.estado }}</span>
    </div>
  }
</div>
