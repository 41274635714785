import { Injectable } from '@angular/core';
import { IEstadoSirena, IListado, IQueryParam } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class EstadosSirenasService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<IEstadoSirena>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/estadosSirenas`, { params });
  }

  public listarPorId(id: string): Observable<IEstadoSirena> {
    return this.http.get(`/estadosSirenas/${id}`);
  }
}
