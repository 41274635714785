import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICliente,
  ICreateCliente,
  IQueryParam,
  IUpdateCliente,
} from 'modelos/src';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';
import { HelperService } from '../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ClientesService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<{ totalCount: number; datos: ICliente[] }> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/clientes`, { params });
  }

  public crear(dato: ICreateCliente): Observable<ICliente> {
    return this.http.post(`/clientes`, dato);
  }

  public subirImagen(
    file: File,
    nombreCLiente: string,
    nombreImagen: string,
    nombreCategoria?: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('nombreImagen', nombreImagen);
    formData.append('nombreCliente', nombreCLiente);
    if (nombreCategoria) {
      formData.append('nombreCategoria', nombreCategoria);
    }

    return this.http.post(`/clientes/cargarimagen`, formData);
  }

  public listarPorId(id: string): Observable<ICliente> {
    return this.http.get(`/clientes/${id}`);
  }

  public editar(id: string, dato: IUpdateCliente): Observable<ICliente> {
    return this.http.put(`/clientes/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/clientes/${id}`);
  }

  public desactivar(id: string): Observable<ICliente> {
    return this.http.put(`/clientes/desactivar/${id}`, {});
  }

  public activar(id: string): Observable<ICliente> {
    return this.http.put(`/clientes/activar/${id}`, {});
  }
}
