import { Injectable } from '@angular/core';
import { ILogSirena, IListado, IQueryParam } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class LogsSirenasService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILogSirena>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/logsSirenas`, { params });
  }

  public listarPorId(id: string): Observable<ILogSirena> {
    return this.http.get(`/logsSirenas/${id}`);
  }

  public eliminar(id: string): Observable<ILogSirena> {
    return this.http.delete(`/logsSirenas/${id}`);
  }
}
