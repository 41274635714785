import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportarComponent } from './exportar/exportar.component';
import { AuxiliaresModule } from '../auxiliares.module';
import { ExportarRoutingModule } from './exportar.routing.module';
import { MonthSelectComponent } from './monthSelect/monthSelect.component';

@NgModule({
  declarations: [ExportarComponent, MonthSelectComponent],
  imports: [CommonModule, AuxiliaresModule, ExportarRoutingModule],
  exports: [ExportarComponent],
})
export class ExportarModule {}
