import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IApiKey,
  ICliente,
  ICreateApiKey,
  IListado,
  IQueryParam,
  IUpdateApiKey,
  IUpdateVecino,
} from 'modelos/src';
import { take } from 'rxjs/operators';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';

import { ApiKeysService } from '../apikeys.service';
import { Subscription } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';

@Component({
  selector: 'app-crear-editar-apikeys',
  templateUrl: './crear-editar-apikeys.component.html',
  styleUrls: ['./crear-editar-apikeys.component.scss'],
})
export class CrearEditarApiKeyComponent implements OnInit, OnDestroy {
  @ViewChild('inputDireccion', { static: true }) inputDireccion:
    | ElementRef
    | undefined;

  public formulario: UntypedFormGroup;
  public loading = false;
  public title = '';

  public clientes?: ICliente[];
  private clientes$?: Subscription;

  public roles = ['admin', 'operador', 'Crear Controles', 'veedor'];

  public queryParams: IQueryParam = {
    page: 0,
    limit: 0,
    sort: 'nombre',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IApiKey,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarApiKeyComponent>,
    private service: ApiKeysService,
    private helper: HelperService,
    private listados: ListadosService,
  ) {
    this.title = this.data?._id ? 'Editar API Key' : 'Crear API Key';
    this.formulario = this.fb.group({
      apiName: [this.data?.apiName],
      fechaCreacion: [this.data?.fechaCreacion || new Date()],
      fechaExpiracion: [this.data?.fechaExpiracion || new Date()],
      key: [this.data?.key],
      idCliente: [this.data?.idCliente],
      roles: [this.data?.roles],
    });
  }
  //

  public close(): void {
    this.dialogRef.close();
  }

  private getCreateData(): ICreateApiKey {
    const data: ICreateApiKey = this.formulario.value;
    return data;
  }

  private getUpdateData(): IUpdateVecino {
    const data: IUpdateApiKey = {
      apiName: this.formulario.get('apiName')?.value,
      fechaExpiracion: this.formulario.get('fechaExpiracion')?.value,
      roles: this.formulario.get('roles')?.value,
    };
    return data;
  }

  public async enviar(): Promise<void> {
    // this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await this.service
          .editar(this.data._id, data)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await this.service.crear(data).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  private async listarClientes(): Promise<void> {
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', this.queryParams)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.listarClientes()]);
  }

  async ngOnDestroy(): Promise<void> {
    this.clientes$?.unsubscribe();
  }
}
