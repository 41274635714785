import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrearEditarPorticoComponent } from './crear-editar-portico/crear-editar-portico.component';
import { PorticosRoutingModule } from './porticos.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListarPorticosComponent } from './listar-porticos/listar-porticos.component';
import { ListadoLecturasPorticoComponent } from './listado-lecturas-portico/listado-lecturas-portico.component';
import { ListadoLecturasPorticosComponent } from './listado-lecturas-porticos/listado-lecturas-porticos.component';

@NgModule({
  declarations: [
    ListarPorticosComponent,
    CrearEditarPorticoComponent,
    ListadoLecturasPorticoComponent,
    ListadoLecturasPorticosComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, PorticosRoutingModule],
})
// eslint-disable-next-line prettier/prettier
export class PorticosModule {}
