<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()"> Botones </span>
    >
    {{ title }}
  </span>
</h2>

<div class="table-container mat-elevation-z8 p-2">
  <form
    id="form"
    *ngIf="formulario"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <div
      fxLayout.lt-md="column"
      fxLayout="row"
      fxLayoutGap.gt-sm="2em"
      fxLayoutAlign="start center"
    >
      <!-- Funcion -->
      <mat-form-field>
        <mat-label>Función</mat-label>
        <mat-select formControlName="funcion" required>
          <mat-option *ngFor="let dato of funciones" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Nombre -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Variante -->
      <mat-form-field>
        <mat-label>Variante</mat-label>
        <input matInput placeholder="Variante" formControlName="variante" />
      </mat-form-field>

      <!-- Código Smart City -->
      <mat-form-field>
        <mat-label>Código Smart City</mat-label>
        <input
          matInput
          placeholder="Botón Alerta Urbana"
          formControlName="codigoSmartCity"
        />
      </mat-form-field>
    </div>

    <!-- Imagen del boton -->
    <mat-tab-group>
      <mat-tab label="Botón Con Imagen">
        <div style="margin-top: 1em">
          <!-- Boton Normal -->
          <div
            class="mb-3"
            fxLayout.lt-md="column"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap.gt-sm="15px"
          >
            <!-- URL -->
            <button
              mat-raised-button
              type="button"
              class="mb-3"
              color="primary"
              (click)="fileInputImagen.click()"
              [disabled]="!nombresValido()"
            >
              <mat-icon>upload</mat-icon>
              <span>Imagen</span>
            </button>

            <input
              #fileInputImagen
              matInput
              type="file"
              style="position: fixed; top: -20000px"
              accept="image/*"
              (change)="setFile($event, 'urlImagen')"
            />
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="URL de la imagen para el botón"
                formControlName="urlImagen"
              />
            </mat-form-field>

            <!-- Muestra de la Imagen -->
            <img
              [src]="formulario.get('urlImagen')?.value"
              alt="Sin Imagen"
              height="100"
            />
          </div>
          <!-- Boton Push -->
          <div
            class="mb-3"
            fxLayout.lt-md="column"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap.gt-sm="15px"
          >
            <!-- URL -->
            <button
              mat-raised-button
              type="button"
              class="mb-3"
              color="primary"
              (click)="fileInputImagenPush.click()"
              [disabled]="!nombresValido()"
            >
              <mat-icon>upload</mat-icon>
              <span>Imagen Push</span>
            </button>

            <input
              #fileInputImagenPush
              matInput
              type="file"
              style="position: fixed; top: -20000px"
              accept="image/*"
              (change)="setFile($event, 'urlImagenPush')"
            />
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="URL de la imagen para el botón pulsado"
                formControlName="urlImagenPush"
              />
            </mat-form-field>

            <!-- Muestra de la Imagen -->
            <img
              [src]="formulario.get('urlImagenPush')?.value"
              alt="Sin Imagen"
              height="100"
            />
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Boton Con Texto">
        <div style="margin-top: 1em">
          <!-- Boton Con texto -->
          <div
            class="mb-3"
            fxLayout.lt-md="column"
            fxLayout="row"
            fxLayoutGap.gt-sm="15px"
          >
            <!-- Texto -->
            <mat-form-field style="width: 33%" class="no-hint">
              <mat-label>Texto</mat-label>
              <input
                matInput
                placeholder="Texto a mostrar en el boton"
                formControlName="texto"
              />
            </mat-form-field>

            <!-- Color -->
            <mat-form-field style="width: 33%" class="no-hint">
              <mat-label>Color de la Alerta</mat-label>
              <input matInput readonly formControlName="color" />
              <ngx-colors
                matSuffix
                ngx-colors-trigger
                formControlName="color"
                (change)="onColorChange($event, 'color')"
              >
              </ngx-colors>
            </mat-form-field>

            <!-- Muestra de boton -->
            <div class="row-center" style="width: 33%">
              <button
                mat-raised-button
                type="button"
                [style.background]="formulario.get('color')?.value"
                [style.color]="
                  helper.invertColor(formulario.get('color')?.value)
                "
              >
                {{ formulario.get("texto")?.value || "EJEMPLO" }}
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <!-- Alerta / Alerta por punto y evento -->
    <ng-container
      *ngIf="
        formulario?.get('funcion')?.value === 'Alerta' ||
        formulario?.get('funcion')?.value === 'Alerta por Punto' ||
        formulario?.get('funcion')?.value === 'Evento'
      "
    >
      <mat-checkbox
        style="margin-bottom: 1em"
        formControlName="requierePreAprobacion"
      >
        La alerta deberá ser aprobada por el administrador del grupo antes de
        llegar a la central de monitoreo.
      </mat-checkbox>
    </ng-container>

    <!-- Solo en funcion Alerta y alerta por punto-->
    <ng-container
      *ngIf="
        formulario?.get('funcion')?.value === 'Alerta' ||
        formulario?.get('funcion')?.value === 'Alerta por Punto'
      "
    >
      <h2>Configuración Alerta</h2>

      <!-- Alerta por punto -->
      <mat-form-field
        *ngIf="formulario?.get('funcion')?.value === 'Alerta por Punto'"
      >
        <mat-label>Tipo de Punto</mat-label>
        <mat-select formControlName="tipoDePunto" required>
          <mat-option *ngFor="let dato of tiposDePunto" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Chechbox -->
      <div fxLayout="column">
        <mat-checkbox style="margin-bottom: 1em" formControlName="global">
          Crear alertas fuera de la zona de cobertura.
        </mat-checkbox>

        <mat-checkbox style="margin-bottom: 1em" formControlName="sirena">
          Activar sirena mas cercana al generar alerta.
        </mat-checkbox>

        <mat-checkbox
          style="margin-bottom: 1em"
          formControlName="finalizacionAutomatica"
        >
          Las <strong>alertas</strong> generadas por este botón se finalizarán
          automáticamente.
        </mat-checkbox>
      </div>

      <!-- Envio Multimedia -->
      <div class="subgrupo" [formGroup]="formularioEnvioMultimedia">
        <h2>Envio Multimedia Manual</h2>

        <div
          class="mb-3"
          fxLayout.lt-md="column"
          fxLayout="row"
          fxLayoutGap.gt-sm="2em"
          fxLayoutAlign="start center"
        >
          <div fxLayout="row" fxLayoutGap="2em">
            <mat-checkbox formControlName="gps">GPS</mat-checkbox>
            <mat-checkbox formControlName="audio">Audio</mat-checkbox>
            <mat-checkbox formControlName="foto">Foto</mat-checkbox>
          </div>
        </div>
      </div>

      <!-- Seguimiento -->
      <div class="subgrupo" [formGroup]="formularioTrackeo">
        <h2>Seguimiento</h2>
        <!-- GPS -->
        <div
          class="mb-3 segmento"
          fxLayout.lt-md="column"
          fxLayout="row"
          fxLayoutGap.gt-sm="2em"
          fxLayoutAlign="start center"
        >
          <div style="width: 100%">
            <mat-checkbox formControlName="gps">GPS</mat-checkbox>

            <div
              class="row-center"
              *ngIf="this.formularioTrackeo.get('gps')?.value === true"
            >
              <mat-form-field style="width: 50%">
                <mat-label>Duración</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="35"
                  formControlName="gpsTiempo"
                  [required]="
                    this.formularioTrackeo.get('gps')?.value === true
                      ? true
                      : false
                  "
                />
                <mat-hint>En minutos</mat-hint>
              </mat-form-field>

              <mat-form-field style="width: 50%">
                <mat-label>Intervalo</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="35"
                  formControlName="gpsIntervalo"
                  [required]="
                    this.formularioTrackeo.get('gps')?.value === true
                      ? true
                      : false
                  "
                />
                <mat-hint>En segundos</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Audio -->
        <div
          class="mb-3 segmento"
          fxLayout.lt-md="column"
          fxLayout="row"
          fxLayoutGap.gt-sm="2em"
          fxLayoutAlign="start center"
        >
          <div style="width: 100%">
            <mat-checkbox formControlName="audio">Audio</mat-checkbox>

            <div
              class="row-center"
              *ngIf="this.formularioTrackeo.get('audio')?.value === true"
            >
              <mat-form-field style="width: 100%">
                <mat-label>Duración</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="35"
                  formControlName="audioTiempo"
                  [required]="
                    this.formularioTrackeo.get('audio')?.value === true
                      ? true
                      : false
                  "
                />
                <mat-hint>En minutos</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Foto -->
        <div
          class="mb-3 segmento"
          fxLayout.lt-md="column"
          fxLayout="row"
          fxLayoutGap.gt-sm="2em"
          fxLayoutAlign="start center"
        >
          <div style="width: 100%">
            <mat-checkbox formControlName="foto">Foto</mat-checkbox>

            <div
              class="row-center"
              *ngIf="this.formularioTrackeo.get('foto')?.value === true"
            >
              <mat-form-field style="width: 50%">
                <mat-label>Cantidad</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="35"
                  formControlName="fotoCantidad"
                  [required]="
                    this.formularioTrackeo.get('foto')?.value === true
                      ? true
                      : false
                  "
                />
              </mat-form-field>

              <mat-form-field style="width: 50%">
                <mat-label>Intervalo</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="35"
                  formControlName="fotoIntervalo"
                  [required]="
                    this.formularioTrackeo.get('foto')?.value === true
                      ? true
                      : false
                  "
                />
                <mat-hint>En segundos</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Sonido Notificaciones -->
      <mat-form-field>
        <mat-label>
          Sonido de notificación para el centro de monitoreo
        </mat-label>
        <mat-select formControlName="notificacion">
          <mat-option *ngFor="let dato of notificaciones" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- Solo en Link -->
    <mat-form-field *ngIf="this.formulario?.get('funcion')?.value === 'Link'">
      <mat-label>Link</mat-label>
      <input matInput placeholder="www.gpesistemas.ar" formControlName="link" />
    </mat-form-field>

    <!-- Solo en funcion evento -->
    <ng-container *ngIf="this.formulario?.get('funcion')?.value === 'Evento'">
      <div fxLayout="column">
        <mat-checkbox style="margin-bottom: 1em" formControlName="global">
          Crear eventos fuera de la zona de cobertura.
        </mat-checkbox>
      </div>

      <!-- Sonido Notificaciones -->
      <mat-form-field>
        <mat-label>
          Sonido de notificación para el centro de monitoreo
        </mat-label>
        <mat-select formControlName="notificacion">
          <mat-option *ngFor="let dato of notificaciones" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <h2>Formulario del evento</h2>

      <div [formGroup]="formularioDinamico">
        <div
          *ngFor="
            let item of formularioDinamico.value | keyvalue: originalOrder
          "
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="1em"
        >
          <!-- Select -->
          <mat-form-field>
            <mat-label>{{ item.key }}</mat-label>
            <mat-select [formControlName]="$any(item.key)" required>
              <mat-option *ngFor="let dato of tiposDatos" [value]="dato">
                {{ dato }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Boton Eliminar -->
          <div fxLayoutAlign="center end">
            <button
              mat-mini-fab
              color="warn"
              matTooltip="Eliminar"
              (click)="eliminarCampo($any(item.key))"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- Agregar campo al formulario dinamico -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
        <mat-form-field>
          <mat-label>Campo</mat-label>
          <input matInput placeholder="Nombre" formControlName="label" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Tipo de dato</mat-label>
          <mat-select formControlName="tipo">
            <mat-option *ngFor="let dato of tiposDatos" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Agregar Integracion -->
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="agregarCampo()"
        >
          Agregar Campo
        </button>
      </div>

      <!-- Envio Multimedia -->
      <div class="subgrupo" [formGroup]="formularioEnvioMultimedia">
        <h2>Envio Multimedia Manual</h2>

        <div
          class="mb-3"
          fxLayout.lt-md="column"
          fxLayout="row"
          fxLayoutGap.gt-sm="2em"
          fxLayoutAlign="start center"
        >
          <div fxLayout="row" fxLayoutGap="2em">
            <mat-checkbox formControlName="foto">Foto</mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
  </form>

  <br />

  <!-- Botones -->
  <div fxLayoutGap="10px" style="text-align: end">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>
    <button mat-raised-button type="button" (click)="volver()">Volver</button>
  </div>
</div>
