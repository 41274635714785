/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IPortico,
  ICoordenadas,
  ICreatePortico,
  ICliente,
  IQueryParam,
  IListado,
  IFilter,
} from 'modelos/src';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { PorticosService } from '../porticos.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';

@Component({
  selector: 'app-crear-editar-portico',
  templateUrl: './crear-editar-portico.component.html',
  styleUrls: ['./crear-editar-portico.component.scss'],
})
export class CrearEditarPorticoComponent implements OnInit {
  coordenadas?: google.maps.LatLngLiteral;
  auxCoordenadas?: ICoordenadas;
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;

  numeros: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  private resizeSubscription: Subscription | undefined;

  public id: string | null = null;
  public data?: IPortico;
  private initialFilters: IFilter<ICliente> = {
    porticos: true,
  };

  public clientes?: ICliente[];
  private clientes$?: Subscription;

  public queryParams: IQueryParam = {
    page: 0,
    limit: 0,
    sort: 'nombre',
    filter: JSON.stringify(this.initialFilters),
  };

  public mapOptions: google.maps.MapOptions = {
    zoom: 12,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    gestureHandling: 'cooperative',
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.TOP_LEFT,
    },
  };
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  private async initMap(): Promise<void> {
    const lat = this.data?.ubicacionManual?.lat;
    // Setear posicion incial
    if (!lat) {
      const currPos = await this.helper.getCurrentPosition();
      this.coordenadas = currPos;
      this.formulario?.patchValue({
        ubicacionManual: this.coordenadas,
      });
    }
    // Definir mapa
    this.coordenadas = this.data?.ubicacionManual || this.coordenadas;
  }

  public setLocation(event: google.maps.MapMouseEvent): void {
    const coords = event.latLng?.toJSON();
    if (coords) {
      this.coordenadas = coords;
      this.auxCoordenadas = this.coordenadas;
      console.log(this.auxCoordenadas);
      this.formulario?.patchValue({
        ubicacionManual: this.auxCoordenadas,
      });
    }
  }

  public get formularioConfig(): UntypedFormGroup {
    return this.formulario?.get('configuracion') as UntypedFormGroup;
  }

  public get formularioConexion(): UntypedFormGroup {
    return this.formulario?.get('conexion') as UntypedFormGroup;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private service: PorticosService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private listados: ListadosService,
    // eslint-disable-next-line prettier/prettier
  ) {}

  public volver(): void {
    window.history.back();
  }
  public clienteNombre(id: string) {
    const cliente = this.clientes?.find((c) => c._id === id);
    return cliente?.nombre;
  }
  // crearlo con los datos correctos de los porticos
  private crearFormulario(): void {
    /*const formFormulario = this.fb.group({});
    if (this.data?.configuracion) {
      Object.entries(this.data.configuracion).forEach(([label, tipo]) => {
        formFormulario.addControl(
          label,
          this.fb.control(tipo, Validators.required),
        );
      });
    }*/
    const configuraciones = this.createDefaultConfiguraciones(10);
    const datos = this.data?.configuraciones || [];

    // Rellenar los formularios con los datos existentes
    datos.forEach((config, index) => {
      if (index < 10) {
        configuraciones.at(index).patchValue(config);
      }
    });

    this.formulario = this.fb.group({
      macAddress: [this.data?.macAddress],
      nombre: [this.data?.nombre],
      idCliente: [this.data?.idCliente],
      fechaColocacion: [this.data?.fechaColocacion],
      marcaCamara: [this.data?.marcaCamara],
      modeloCamara: [this.data?.modeloCamara],
      ubicacionManual: [this.data?.ubicacionManual],
      imgFondo: [this.data?.imgFondo],
      tamFondo: [this.data?.tamFondo],
      configuraciones: configuraciones,
      cantCamaras: [this.data?.cantCamaras],
    });
    console.log(this.formulario);
  }

  createDefaultConfiguraciones(count: number): FormArray {
    const formArray = this.fb.array([]);
    for (let i = 0; i < count; i++) {
      formArray.push(
        this.fb.group({
          numeroCamara: [null],
          disSupP: [null],
          disIzqP: [null],
          tamP: [null],
        }),
      );
    }
    return formArray;
  }

  get configuraciones(): FormArray {
    return this.formulario?.get('configuraciones') as FormArray;
  }

  //

  private getData(): ICreatePortico {
    if (this.formulario) {
      this.formulario.value.configuraciones =
        this.formulario.value.configuraciones.splice(
          0,
          this.formulario.value.cantCamaras,
        );
      console.log(this.formulario.value.configuraciones);
    }
    const data: ICreatePortico = this.formulario?.value;
    delete (data as any).label;
    delete (data as any).tipo;
    return data;
  }

  public async enviar(): Promise<void> {
    //FALTA AGREGAR LOS FORM GROUP CORRESPONDIENTES PARA SACAR LA CONFIG
    this.loading = true;
    try {
      const data = this.getData();
      console.log('info del formulario', data);
      if (this.data?._id) {
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  private async listar(id: string): Promise<void> {
    this.data = await firstValueFrom(this.service.listarPorId(id));
    console.log('data del listar por id');
    console.log(this.data);
  }

  private async listarClientes(): Promise<void> {
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', this.queryParams)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', this.queryParams);
  }

  // este es para subir una imagen
  /*public async setFile(event: Event, formControlName: string): Promise<void> {
    this.loading = true;
    const file = (event as any).target?.files?.item(0);
    const nombreBoton = this.formulario?.get('nombre')?.value;
    const fileName = `porticos/${nombreBoton}/${formControlName}`;

    const res = await firstValueFrom(
      this.service.subirImagenPublica(file, fileName),
    );

    const val = res.url + `?${(Math.random() * 1000).toFixed(0)}`;
    this.formulario?.patchValue({ [formControlName]: val });
    this.loading = false;
  }*/

  getPatentes(): number[] {
    return Array.from(
      { length: this.formulario?.get('cantCamaras')?.value },
      (_, i) => i + 1,
    );
  }

  public nombresValido(): boolean {
    const nombre = this.formulario?.get('nombre')?.value;
    if (nombre) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    if (this.id) {
      await this.listar(this.id);
    }
    await Promise.all([this.listarClientes()]);
    await this.crearFormulario();
    this.initMap();
  }
}
