/* eslint-disable @typescript-eslint/no-explicit-any */
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ILecturaPortico, IFilter, IListado, IQueryParam } from 'modelos/src';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { ActivatedRoute } from '@angular/router';
import { PorticosService } from '../porticos.service';
import { IRegExpSearch } from 'src/app/auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-listado-lecturas-porticos',
  templateUrl: './listado-lecturas-porticos.component.html',
  styleUrls: ['./listado-lecturas-porticos.component.scss'],
})
export class ListadoLecturasPorticosComponent implements OnInit {
  public loading = false;

  public search: IRegExpSearch = {
    fields: ['patente'],
  };
  public columnas = [
    'patente',
    'velocidad',
    'fechaCreacion',
    'portico',
    'cliente',
  ];
  public nombreColumnas = [
    'Patente',
    'Velocidad',
    'Fecha Lectura',
    'Portico',
    'Cliente',
  ];
  public saveColumnas = 'tabla-lecturas-porticos';
  public dataSource = new MatTableDataSource<ILecturaPortico>([]);
  private initialFilters: IFilter<ILecturaPortico> = {};
  private populate = [
    {
      path: 'portico',
      select: 'nombre',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  // FILTRO BUSQUEDA Y PAGINACION
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'fechaCreacion',
    select: '',
    filter: JSON.stringify(this.initialFilters),
    populate: JSON.stringify(this.populate),
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private route: ActivatedRoute,
    private porticoService: PorticosService,
    // private dialogService: DialogService,
    // private service: EventosBalizasService,
    // public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }

  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  public async cambioFiltro(filters: IFilter<ILecturaPortico>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listarLecturas();
    this.loading = false;
  }

  public volver(): void {
    window.history.back();
  }

  private async listarLecturas(): Promise<void> {
    // Query
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ILecturaPortico>>(
        'lecturasPorticos',
        this.queryParams,
      )
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de lecturasPorticos`,
          data,
        );
      });
    await this.listadosService.getLastValue(
      'lecturasPorticos',
      this.queryParams,
    );
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.actualizar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
  }
}
