<div fxLayout="column" fxLayout.gt-sm="row" fxFill>
  <div fxFlex="140px" fxFlex.gt-sm="605px" class="background">
    <!-- <img class="bg-image" [src]="loginImage">   -->

    <!-- <img class="logo" [src]="logo"> -->

    <div class="contenedor-texto-imagen">
      <div class="texto-imagen">
        <h1 class="login-title">{{ loginTitle }}</h1>
        <h4 class="texto">{{ loginText }}</h4>
      </div>
    </div>
  </div>

  <div fxFlex class="content">
    <div class="body">
      <div style="max-width: 100%">
        <div class="titulo-login">Panel de administración</div>
        <div class="titulo-login">Iniciar sesión</div>

        <form [formGroup]="loginForm!" novalidate (ngSubmit)="onSubmit()">
          <div fxLayout="column">
            <mat-form-field appearance="fill">
              <mat-label>Usuario</mat-label>
              <input matInput formControlName="username" />
              <mat-error
                *ngIf="loginForm?.get('username')?.hasError('required')"
              >
                El usuario es obligatorio
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Contraseña</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
              />
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
              >
                <mat-icon>{{
                  hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
              <mat-error> La contraseña es obligaria </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" dir="rtl" class="boton">
            <div fxLayout="column" fxLayoutAlign="center center">
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="loading"
              >
                <div
                  class="loadSpinnerButton"
                  [style.display]="loading ? 'flex' : 'none'"
                >
                  <mat-spinner
                    class="mx-2"
                    [diameter]="25"
                    mode="indeterminate"
                    color="warn"
                  ></mat-spinner>
                </div>
                <span [style.display]="loading ? 'none' : 'block'"
                  >Ingresar</span
                >
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="footer">
      <div>
        <span>
          © 2023 - Versión {{ version }}
          <span *ngIf="env !== 'prod'">{{ env }}</span>
        </span>
      </div>
      <div class="footer-links"></div>
    </div>
  </div>
</div>
