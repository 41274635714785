<div class="mx-3 mat-elevation-z2">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutGap="1em"
    style="padding: 0.5em 1em"
  >
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start end"
      fxLayoutGap="1em"
      style="padding: 0 1em"
    >
      <!-- Select Cliente -->
      <ng-select
        style="width: 200px"
        [items]="clientes"
        [(ngModel)]="idCliente"
        [hideSelected]="true"
        placeholder="Cliente"
        [searchable]="true"
        bindLabel="nombre"
        bindValue="_id"
        (change)="listar()"
      >
      </ng-select>

      <!-- Select Modo Registro -->
      <ng-select
        style="width: 300px"
        [items]="modosRegistro"
        [(ngModel)]="modoRegistro"
        [hideSelected]="true"
        placeholder="Modo de Registro"
        [searchable]="true"
        bindLabel="viewValue"
        bindValue="value"
        (change)="listar()"
      >
      </ng-select>

      <!-- Agrupacion -->
      <mat-form-field style="width: 300px">
        <mat-label>Agrupación</mat-label>
        <mat-select
          [(ngModel)]="agrupacion"
          (selectionChange)="hacerGraficoFechaCreacion()"
        >
          <mat-option value="Modo de Registro"> Modo de Registro </mat-option>
          <mat-option value="Cliente"> Cliente </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Resumen -->
    <div fxLayout="column">
      <p style="margin: 0">
        Registrados en los últimos {{ dias }} días:
        <strong>{{ totalCount }}</strong>
      </p>
      <p style="margin: 0">
        Promedio diario: <strong>{{ promedioDiario }}</strong>
      </p>
      <p style="margin: 0">
        Registrados Hoy: <strong>{{ registradosHoy }}</strong>
      </p>
    </div>
  </div>

  <!-- Graficos -->
  <div>
    <mat-tab-group>
      <mat-tab label="Cantidad de registros por día">
        <ng-template matTabContent>
          <app-chart
            *ngIf="chartOptionsFechaCreacion"
            [(options)]="chartOptionsFechaCreacion"
            style="
              max-width: 100%;
              height: 400px;
              display: block;
              margin-top: 30px;
              margin: auto;
            "
          ></app-chart>
        </ng-template>
      </mat-tab>
      <mat-tab label="Promedio de registros por hora">
        <ng-template matTabContent>
          <app-chart
            *ngIf="chartOptionsHoraHoy"
            [(options)]="chartOptionsHoraHoy"
            style="
              max-width: 100%;
              height: 400px;
              display: block;
              margin-top: 30px;
              margin: auto;
            "
          ></app-chart>
          <!-- <div fxLayout="row">
            <div fxFlex="50">
              <app-chart
                *ngIf="chartOptionsHoraCreacion"
                [(options)]="chartOptionsHoraCreacion"
                style="
                  max-width: 100%;
                  height: 400px;
                  display: block;
                  margin-top: 30px;
                  margin: auto;
                "
              ></app-chart>
            </div>

            <div fxFlex="50">
              <app-chart
                *ngIf="chartOptionsHoraHoy"
                [(options)]="chartOptionsHoraHoy"
                style="
                  max-width: 100%;
                  height: 400px;
                  display: block;
                  margin-top: 30px;
                  margin: auto;
                "
              ></app-chart>
            </div>
          </div> -->
        </ng-template>
      </mat-tab>
      <mat-tab label="Cantidad de regitros por edad">
        <ng-template matTabContent>
          <app-chart
            *ngIf="chartOptionsEdad"
            [(options)]="chartOptionsEdad"
            style="
              max-width: 100%;
              height: 400px;
              display: block;
              margin-top: 30px;
              margin: auto;
            "
          ></app-chart>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
