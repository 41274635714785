import { Injectable } from '@angular/core';
import {
  IConfigVecino,
  ICreateConfigVecino,
  IListado,
  IQueryParam,
  IUpdateConfigVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from 'src/app/auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigVecinosService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<IConfigVecino>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/configvecinos`, { params });
  }

  public listarPorId(id: string): Observable<IConfigVecino> {
    return this.http.get(`/configvecinos/${id}`);
  }

  public crear(dato: ICreateConfigVecino): Observable<IConfigVecino> {
    return this.http.post(`/configvecinos`, dato);
  }

  public editar(
    id: string,
    dato: IUpdateConfigVecino
  ): Observable<IConfigVecino> {
    return this.http.put(`/configvecinos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/configvecinos/${id}`);
  }
}
