<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="formulario"
      id="form"
      [formGroup]="formulario"
      (ngSubmit)="enviar()"
      autocomplete="off"
    >
      <mat-form-field>
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="idCliente" required>
          <mat-option *ngFor="let dato of clientes" [value]="dato._id">{{
            dato.nombre
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Roles</mat-label>
        <mat-select formControlName="roles" required multiple>
          <mat-option *ngFor="let dato of roles" [value]="dato">{{
            dato
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Usuario</mat-label>
        <input
          matInput
          placeholder="Usuario"
          formControlName="usuario"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Clave</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'search'"
          autocomplete="new-password"
          placeholder="Clave"
          formControlName="clave"
          [required]="this.data ? false : true"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="$event.stopPropagation(); hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Correo Electrónico</mat-label>
        <input
          matInput
          placeholder="Correo Electrónico"
          formControlName="email"
        />
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-around end" fxLayoutGap="10px">
        <mat-form-field>
          <mat-label>Pais</mat-label>
          <mat-select formControlName="pais">
            <mat-option
              *ngFor="let dato of phoneCodes"
              [value]="dato.dial_code"
              [disabled]="dato.disabled"
            >
              {{ dato.name }} {{ dato.dial_code }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Teléfono</mat-label>
          <input
            class="telefono"
            type="number"
            min="0"
            step="1"
            matInput
            placeholder="11 60606060"
            formControlName="telefono"
          />
        </mat-form-field>
      </div>
      <mat-checkbox
        formControlName="sirenasOnline"
        style="margin-bottom: 1em"
      >
        Ver Sirenas Siempre Online
      </mat-checkbox>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="close()">Volver</button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
