import { Injectable } from '@angular/core';
import {
  ICreateUsuario,
  IQueryParam,
  IUpdateUsuario,
  IUsuario,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<{ totalCount: number; datos: IUsuario[] }> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/usuarios`, { params });
  }

  public listarPropio(): Observable<IUsuario> {
    return this.http.get(`/usuarios/propio`);
  }

  public listarPorId(id: string): Observable<IUsuario> {
    return this.http.get(`/usuarios/${id}`);
  }

  public crear(dato: ICreateUsuario): Observable<IUsuario> {
    return this.http.post(`/usuarios`, dato);
  }

  public editar(id: string, dato: IUpdateUsuario): Observable<IUsuario> {
    return this.http.put(`/usuarios/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/usuarios/${id}`);
  }

  public desactivar(id: string): Observable<IUsuario> {
    return this.http.put(`/usuarios/desactivar/${id}`, {});
  }

  public activar(id: string): Observable<IUsuario> {
    return this.http.put(`/usuarios/activar/${id}`, {});
  }
}
