import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IConfigVecino } from 'modelos/src';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'app-config-vecino',
  templateUrl: './config-vecino.component.html',
  styleUrls: ['./config-vecino.component.scss'],
})
export class ConfigVecinoComponent implements OnInit {
  @Input() config?: IConfigVecino;
  constructor(private snackBar: MatSnackBar) {}

  public copiar(texto: string) {
    navigator.clipboard.writeText(texto);
    this.snackBar.open('Dirección copiada al portapapeles', 'Cerrar', {
      duration: 2000,
    });
  }

  ngOnInit(): void {}
}
