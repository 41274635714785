<div class="container" *ngIf="mantenimiento">
  <h2 class="m-4"><span class="titulo">Detalles</span></h2>
  <div class="header">
    <div class="centerRow">
      <div style="width: 60%; text-align: center">
        <h2>{{ mantenimiento.fecha | date : "short" }}</h2>
        <h2>{{ mantenimiento.usuario?.nombre || mantenimiento.idUsuario }}</h2>
        <h2>{{ mantenimiento.cliente?.nombre || mantenimiento.idCliente }}</h2>
        <h2>{{ mantenimiento.sirena?.chipId }}</h2>
        <h2>{{ mantenimiento.sirena?.direccionGps }}</h2>
      </div>
      <div style="width: 40%; text-align: center">
        <img [src]="mantenimiento.urlFoto" alt="foto" style="width: 100%" />
      </div>
    </div>
    <br />
    <br />
    <div class="centerColumn">
      <div class="tags centerRow">
        <p
          *ngFor="let tag of mantenimiento.tags"
          [style.background-color]="helper.stringToColour(tag)"
        >
          {{ tag }}
        </p>
      </div>
      <br />
      <br />
      <div class="descripcion">
        <p>
          {{ mantenimiento.descripcion }}
        </p>
      </div>
    </div>
  </div>
</div>
