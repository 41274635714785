import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoControlesComponent } from './listado-controles/listado-controles.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ControlesRoutingModule } from './controles.routing.module';
import { ControlesComponent } from './controles/controles.component';
import { CrearEditarControlesComponent } from './crear-editar-controles/crear-editar-controles.component';
import { ListadoControlesSirenasComponent } from './listado-controles-sirenas/listado-controles-sirenas.component';
import { CrearControlesComponent } from './crear-controles/crear-controles.component';

@NgModule({
  declarations: [
    ControlesComponent,
    ListadoControlesComponent,
    CrearEditarControlesComponent,
    ListadoControlesSirenasComponent,
    CrearControlesComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, ControlesRoutingModule],
})
export class ControlesModule {}
