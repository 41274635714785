<h2 mat-dialog-title class="modal-header">Cargar Firmware</h2>

<mat-dialog-content>
  <form
    *ngIf="formulario"
    id="form"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <button
      mat-raised-button
      type="button"
      class="mb-3"
      matTooltip="Cargar Firmware"
      color="primary"
      (click)="fileInput.click()"
    >
      <mat-icon>memory</mat-icon>
      <span>Seleccionar un archivo</span>
    </button>
    <input
      #fileInput
      class="input-hide"
      type="file"
      accept="*/*"
      (change)="setFile($event)"
    />

    <p>Archivo seleccionado: {{ file?.name }}</p>

    <mat-form-field>
      <mat-label>Versión</mat-label>
      <input
        matInput
        placeholder="Versión"
        formControlName="version"
        required
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button
    class="botonSpinner"
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!formulario.valid || !file || loading"
    form="form"
  >
    <div class="loadSpinnerButton" [style.display]="loading ? 'flex' : 'none'">
      <mat-spinner
        class="mx-2"
        [diameter]="25"
        mode="indeterminate"
        color="warn"
      ></mat-spinner>
    </div>
    <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
  </button>

  <button mat-raised-button type="button" (click)="close()">Volver</button>
</mat-dialog-actions>
