import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ICliente,
  IFilter,
  IListado,
  IQueryParam,
  IUsuario,
  ROLES,
} from 'modelos/src';
import { ISocketMessage } from 'modelos/src';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { UsuariosService } from '../usuarios.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { CrearEditarUsuarioComponent } from '../crear-editar-usuario/crear-editar-usuario.component';
import { AuthService } from '../../login/auth.service';

declare type mainType = IUsuario;

@Component({
  selector: 'app-listar-usuarios',
  templateUrl: './listar-usuarios.component.html',
  styleUrls: ['./listar-usuarios.component.scss'],
})
export class ListarUsuariosComponent implements OnInit {
  public search: IRegExpSearch = {
    fields: ['nombre', 'usuario', 'email', 'telefono'],
  };
  public roles: IFiltroTabla = {
    elementos: [
      {
        nombre: 'Admin',
        _id: 'admin',
      },
      {
        nombre: 'Operador',
        _id: 'operador',
      },
      {
        nombre: 'Crear Controles',
        _id: 'Crear Controles',
      },
    ],
    filter: {
      field: 'roles',
      value: 'admin',
    },
    label: 'Rol',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public clientes: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public estado: IFiltroTabla = {
    elementos: [
      { nombre: 'Activos', _id: 'true' },
      { nombre: 'Inactivos', _id: 'false' },
    ],
    filter: {
      field: 'activo',
      value: true,
    },
    label: 'Estado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public usuarioActual?: IUsuario;

  public loading = true;
  public columnas = [
    'usuario',
    'cliente.nombre',
    'nombre',
    'roles',
    'telefono',
    'email',
    'acciones',
  ];
  public nombreColumnas = [
    'Usuario',
    'Cliente',
    'Nombre',
    'Roles',
    'Teléfono',
    'Email',
    'Acciones',
  ];
  public saveColumnas = 'tabla-usuarios';
  public dataSource = new MatTableDataSource<mainType>([]);
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public totalCount = 0;
  private initialFilters: IFilter<IUsuario> = {
    activo: true,
    roles: 'admin',
  };
  private populate = {
    path: 'cliente',
    select: 'nombre',
  };
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'usuario',
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify(this.initialFilters),
  };
  // Listado Continuo
  private datos$?: Subscription;
  private clientes$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private listadosService: ListadosService,
    private service: UsuariosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public authService: AuthService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearEditarUsuarioComponent, config);
  }
  public async editar(data: mainType) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarUsuarioComponent, config);
  }
  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el usuario ${dato.usuario}?`
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async desactivar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desactivar el usuario ${dato.usuario}? El mismo ya no podrá iniciar sesión.`
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.desactivar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('El usuario se ha desactivado');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async activar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Activar el usuario ${dato.usuario}? El mismo podrá iniciar sesión.`
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.activar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('El usuario se ha activado');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(new Date().toLocaleString(), `listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes.elementos = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.usuarioActual = this.authService.getUsuario();
    await Promise.all([this.actualizar(), this.listarClientes()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
