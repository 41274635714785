<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4">
  <span class="titulo">{{ titulo }}</span>
</h2>

<div>
  <app-graficos-sirenas></app-graficos-sirenas>

  <mat-tab-group class="mx-3" style="margin-top: 1em">
    <mat-tab label="Sirenas">
      <ng-template matTabContent>
        <app-listado-sirenas [(loading)]="loading"></app-listado-sirenas>
      </ng-template>
    </mat-tab>
    <mat-tab label="Sims">
      <ng-template matTabContent>
        <app-listado-sims [(loading)]="loading"></app-listado-sims>
      </ng-template>
    </mat-tab>
    <mat-tab label="Firmware">
      <ng-template matTabContent>
        <app-listado-firmwares [(loading)]="loading"></app-listado-firmwares>
      </ng-template>
    </mat-tab>
    <mat-tab label="Firm. X Cliente">
      <ng-template matTabContent>
        <app-listado-firmwares-cliente
          [(loading)]="loading"
        ></app-listado-firmwares-cliente>
      </ng-template>
    </mat-tab>
    <mat-tab label="Firmware X Versión">
      <ng-template matTabContent>
        <app-listado-firmwares-version
          [(loading)]="loading"
        ></app-listado-firmwares-version>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
