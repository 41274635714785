import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import {
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  ISirena,
} from 'modelos/src';
import { Subscription, fromEvent } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';
import {
  Options,
  PointClickEventObject,
  PointOptionsObject,
  SeriesPieOptions,
} from 'highcharts';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-graficos-sirenas',
  templateUrl: './graficos-sirenas.component.html',
  styleUrls: ['./graficos-sirenas.component.scss'],
})
export class GraficosSirenasComponent implements OnInit {
  @ViewChildren('firmwareSearch')
  firmwareSearch?: ElementRef<HTMLInputElement>[];

  public loading = true;
  public titulo = `Sirenas`;

  public totalCount = 0;
  public sirenas?: ISirena[];
  public clientes: ICliente[] = [];

  public idCliente?: string;
  public firmware?: string;
  public estado?: boolean;

  public datos$?: Subscription;
  public clientes$?: Subscription;

  public chartOptionsFirmware?: Options;
  public chartOptionsOnline?: Options;
  public chartOptionsCliente?: Options;

  constructor(
    private listadosService: ListadosService,
    private helper: HelperService
  ) {}

  public async listar(): Promise<void> {
    const filter: IFilter<ISirena> = {};

    if (this.idCliente) {
      filter.idCliente = this.idCliente;
    } else {
      delete filter.idCliente;
    }
    if (this.firmware) {
      if (this.firmware.toLowerCase() === 'avatis') {
        filter.tipo = 'Avatis';
      } else {
        delete filter.tipo;
        filter.versionFirmware = { $regex: this.firmware, $options: 'i' };
      }
    } else {
      delete filter.versionFirmware;
    }
    if (this.estado === true || this.estado === false) {
      filter.online = this.estado;
    } else {
      delete filter.online;
    }

    const populate: IPopulate = {
      path: 'cliente',
      select: 'nombre',
    };

    const queryParams: IQueryParam = {
      select: 'versionFirmware online idCliente',
      populate: JSON.stringify(populate),
      filter: JSON.stringify(filter),
    };

    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ISirena>>('sirenas', queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.sirenas = data.datos;
        this.titulo = `Sirenas Totales: ${this.totalCount}`;
        console.debug(new Date().toLocaleString(), `listado de sirenas`, data);
        this.pieSirenaPorFw();
        this.pieSirenaPorEstado();
        this.pieSirenaPorCliente();
      });
    await this.listadosService.getLastValue('sirenas', queryParams);
  }

  private async listarClientes(): Promise<void> {
    if (!this.helper.puedeListarClientes()) return;

    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  // Crear Graficos

  private pieChart(title: string, series: SeriesPieOptions[]) {
    const chartOptions: Options = {
      title: {
        text: title,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
            distance: -50,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4,
            },
          },
          showInLegend: true,
        },
      },
      series,
      legend: {
        labelFormat: '<b>{name}</b> ({y})',
        maxHeight: 100,
      },
    };
    return chartOptions;
  }

  private getStringColors(strs: string[]): string[] {
    const colors: string[] = [];
    strs.forEach((str) => {
      colors.push(this.helper.stringToColour(str));
    });
    return colors;
  }

  private pieSirenaPorFw(): void {
    // To count the unique elements repetitions on an array
    // of objects in TypeScript and create a pie chart using Highcharts, you can follow these steps:
    // Follow the first three steps in the previous answer to count the unique elements and their counts on the array of objects.
    // Create an array of objects that Highcharts can use to create the pie chart.
    // Each object should have two properties: name (the name of the unique element) and y (the count of that element).
    // Pass the array of objects to Highcharts and create the pie chart.

    const versiones: { [key: string]: number } = {};

    this.sirenas?.forEach((sirena) => {
      if (versiones[sirena.versionFirmware!]) {
        versiones[sirena.versionFirmware!]++;
      } else {
        versiones[sirena.versionFirmware!] = 1;
      }
    });

    let data: PointOptionsObject[] = Object.keys(versiones).map((name) => {
      return {
        name: name !== 'undefined' ? name : 'Avatis',
        y: versiones[name],
        events: {
          click: async (event: PointClickEventObject) => {
            this.firmware = `${event.point.name}`;
            await this.listar();
          },
        },
      };
    });

    data = data.sort((a, b) => b.y! - a.y!);

    const title = 'Sirenas por Firmware';
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Sirenas',
        colors: this.getStringColors(data.map((d) => d.name + 'random')),
        data,
      },
    ];

    this.chartOptionsFirmware = this.pieChart(title, series);
  }

  private pieSirenaPorEstado(): void {
    const datos: PointOptionsObject[] = [
      {
        name: 'Online',
        y: 0,
        events: {
          click: async (event: PointClickEventObject) => {
            this.estado = true;
            await this.listar();
          },
        },
      },
      {
        name: 'Offline',
        y: 0,
        events: {
          click: async (event: PointClickEventObject) => {
            this.estado = false;
            await this.listar();
          },
        },
      },
    ];
    this.sirenas?.forEach((sirena) => {
      if (sirena.online) {
        datos[0].y!++;
      } else {
        datos[1].y!++;
      }
    });

    const title = 'Sirenas por Estado';
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Sirenas',
        // colors: this.getStringColors(datos.map((d) => d.name)),
        colors: ['#77dd77', '#dd7777'],
        data: datos,
      },
    ];

    this.chartOptionsOnline = this.pieChart(title, series);
  }

  private pieSirenaPorCliente(): void {
    const clientes: { [key: string]: number } = {};

    this.sirenas?.forEach((sirena) => {
      if (sirena.cliente) {
        if (clientes[sirena.cliente.nombre!]) {
          clientes[sirena.cliente.nombre!]++;
        } else {
          clientes[sirena.cliente.nombre!] = 1;
        }
      }
    });

    let data: PointOptionsObject[] = Object.keys(clientes).map((name) => {
      return {
        name,
        y: clientes[name],
        events: {
          click: async (event: PointClickEventObject) => {
            this.idCliente = this.clientes?.find(
              (c) => c.nombre === event.point.name
            )?._id;
            await this.listar();
          },
        },
      };
    });

    data = data.sort((a, b) => b.y! - a.y!);

    const title = 'Sirenas por Cliente';
    const series: SeriesPieOptions[] = [
      {
        type: 'pie',
        name: 'Sirenas',
        colors: this.getStringColors(data.map((d) => d.name + 'random')),
        data,
      },
    ];

    this.chartOptionsCliente = this.pieChart(title, series);
  }

  private initFirmwareChange(): void {
    if (this.firmwareSearch?.length) {
      this.firmwareSearch.forEach((input, index) => {
        fromEvent(input.nativeElement, 'keyup')
          .pipe(
            map((e: any) => e.target.value),
            debounceTime(500),
            distinctUntilChanged()
          )
          .subscribe((text: string) => {
            this.listar();
          });
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.listar(), this.listarClientes()]);
    this.initFirmwareChange();
  }
}
