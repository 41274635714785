<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="formulario"
      id="form"
      [formGroup]="formulario"
      (ngSubmit)="enviar()"
      autocomplete="off"
    >
      <div class="row" style="width: 100%">
        <div style="width: 50%">
          <mat-form-field>
            <mat-label>Cliente</mat-label>
            <input matInput [disabled]="true" [value]="clienteValue()" />
          </mat-form-field>
        </div>

        <div style="width: 50%">
          <mat-form-field>
            <mat-label>Sirena</mat-label>
            <input matInput [disabled]="true" [value]="this.sirena?.chipId" />
          </mat-form-field>
        </div>
      </div>

      <mat-form-field>
        <mat-label>Dirección de la Sirena</mat-label>
        <input matInput [disabled]="true" [value]="this.sirena?.direccionGps" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Fecha</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="fecha"
          (click)="picker.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <!-- <mat-form-field>
      <mat-label>Tags</mat-label>
      <mat-select formControlName="tags" required multiple>
        <mat-option *ngFor="let dato of tags" [value]="dato">{{
          dato
        }}</mat-option>
      </mat-select>
    </mat-form-field> -->

      <!-- Select Tags -->
      <ng-select
        [items]="tags"
        formControlName="tags"
        [hideSelected]="true"
        placeholder="Tags"
        [searchable]="true"
        bindLabel="tags"
        bindValue="tags"
        [multiple]="true"
        [addTag]="true"
        addTagText="Agregar tag"
        (add)="agregarTag($event)"
        notFoundText="Agregá un tag nuevo al escribir en el input"
      >
      </ng-select>

      <mat-form-field>
        <mat-label>Descripción</mat-label>
        <textarea matInput formControlName="descripcion" rows="5"></textarea>
      </mat-form-field>
      <div style="width: 100%">
        <app-upload-image-formless
          [nombreCliente]="cliente?.nombre"
          [(url)]="urlFoto"
        ></app-upload-image-formless>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="close()">Volver</button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
