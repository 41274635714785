<h2 mat-dialog-title class="modal-header">
  Actualización de firmware por versión
</h2>

<mat-dialog-content>
  <form
    *ngIf="formulario"
    id="form"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <ng-select
      [items]="firmwares"
      [addTag]="true"
      [loading]="loading"
      bindLabel="version"
      bindValue="version"
      formControlName="versionActual"
      loadingText="Cargando..."
      typeToSearchText="Buscar..."
      notFoundText="No se encontraron resultados"
      appendTo="body"
      [placeholder]="'Versión Actual'"
    >
    </ng-select>

    <ng-select
      [items]="firmwares"
      [addTag]="false"
      [loading]="loading"
      bindLabel="version"
      bindValue="version"
      formControlName="versionNueva"
      loadingText="Cargando..."
      typeToSearchText="Buscar..."
      notFoundText="No se encontraron resultados"
      appendTo="body"
      [placeholder]="'Versión Nueva'"
    >
    </ng-select>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button
    class="botonSpinner"
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!formulario?.valid || loading"
    form="form"
  >
    <div class="loadSpinnerButton" [style.display]="loading ? 'flex' : 'none'">
      <mat-spinner
        class="mx-2"
        [diameter]="25"
        mode="indeterminate"
        color="warn"
      ></mat-spinner>
    </div>
    <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
  </button>

  <button mat-raised-button type="button" (click)="close()">Volver</button>
</mat-dialog-actions>
