<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Actualizaciones de Firmware </span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[etapa]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
  </div>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha  -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha:</span>
        {{ row.fecha | date : "short" }}
      </mat-cell>
    </ng-container>

    <!-- Columna FirmwareActual  -->
    <ng-container matColumnDef="firmwareActual">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Firmware Actual
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Firmware Actual:</span>
        {{ row.firmwareActual }}
      </mat-cell>
    </ng-container>

    <!-- Columna etapa  -->
    <ng-container matColumnDef="etapa">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Etapa
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Etapa:</span>
        {{ row.etapa }}
      </mat-cell>
    </ng-container>

    <!-- Columna firmwareNuevo  -->
    <ng-container matColumnDef="firmwareNuevo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Firmware Nuevo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Firmware Nuevo:</span>
        {{ row.firmwareNuevo }}
      </mat-cell>
    </ng-container>

    <!-- Columna chipId  -->
    <ng-container matColumnDef="chipIdSirena">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        ChipId
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">ChipId:</span>
        {{ row.chipIdSirena }}
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <button
          class="mx-1 no-shadow"
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>
