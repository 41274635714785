<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()">Categorías</span>
    >
    {{ title }}
  </span>
</h2>

<div class="table-container mat-elevation-z8 p-2">
  <form
    id="form"
    *ngIf="formulario"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre" formControlName="nombre" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Variante</mat-label>
      <input matInput placeholder="Variante" formControlName="variante" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>nombre del template</mat-label>
      <input
        matInput
        placeholder="Nombre para el template"
        formControlName="nombreTemplate"
      />
    </mat-form-field>

    <div class="mb-3" fxLayout="row" fxLayoutGap="15px">
      <mat-form-field style="width: 100%">
        <mat-label>Color de la Alerta</mat-label>
        <input matInput readonly formControlName="color" />
        <ngx-colors
          matSuffix
          ngx-colors-trigger
          formControlName="color"
          (change)="onColorChange($event, 'color')"
        >
        </ngx-colors>
      </mat-form-field>

      <div
        class="color-container"
        [style.background]="formulario.get('color')?.value"
        [style.color]="helper.invertColor(formulario.get('color')?.value)"
      >
        ALERTA
      </div>
    </div>

    <mat-form-field>
      <mat-label>Prioridad</mat-label>
      <input
        matInput
        type="number"
        placeholder="Prioridad, número mas bajo es mayor prioridad"
        formControlName="prioridad"
      />
    </mat-form-field>

    <!-- Botones Principales => Primer Pantalla -->
    <ng-select
      [items]="botones"
      [(ngModel)]="idsPrincipalesSelecionados"
      [ngModelOptions]="{ standalone: true }"
      [hideSelected]="true"
      placeholder="Botones Principales"
      [multiple]="true"
      [searchFn]="searchBoton"
      [closeOnSelect]="false"
      bindValue="_id"
      bindLabel="nombre"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>
    </ng-select>

    <!-- Orden Principales -->
    <div
      *ngIf="idsPrincipalesSelecionados?.length"
      cdkDropList
      class="list"
      (cdkDropListDropped)="dropPrincipales($event)"
    >
      <div
        class="box"
        *ngFor="let id of idsPrincipalesSelecionados; let i = index"
        cdkDrag
      >
        <div fxLayout="row" fxFill fxLayoutGap="12px">
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
          <mat-icon
            cdkDragHandle
            color="accent"
            [ngClass]="{ 'cursor-move': true }"
            >drag_indicator</mat-icon
          >
          <span>{{ i + 1 }}</span>
          <span>{{ btnName(id) || id }}</span>
        </div>
      </div>
    </div>

    <!-- Botones Secundarios => Segunda Pantalla -->
    <ng-select
      [items]="botones"
      [(ngModel)]="idsSecundariosSelecionados"
      [ngModelOptions]="{ standalone: true }"
      [hideSelected]="true"
      placeholder="Botones Secundarios"
      [multiple]="true"
      [searchFn]="searchBoton"
      [closeOnSelect]="false"
      bindValue="_id"
      bindLabel="nombre"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>
    </ng-select>

    <!-- Orden Secundarios -->
    <div
      *ngIf="idsSecundariosSelecionados?.length"
      cdkDropList
      class="list"
      (cdkDropListDropped)="dropSecundarios($event)"
    >
      <div
        class="box"
        *ngFor="let id of idsSecundariosSelecionados; let i = index"
        cdkDrag
      >
        <div fxLayout="row" fxFill fxLayoutGap="12px">
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
          <mat-icon
            cdkDragHandle
            color="accent"
            [ngClass]="{ 'cursor-move': true }"
            >drag_indicator</mat-icon
          >
          <span>{{ i + 1 }}</span>
          <span>{{ btnName(id) || id }}</span>
        </div>
      </div>
    </div>

    <!-- Otros Botones => Tercer Pantalla -->
    <ng-select
      [items]="botones"
      [(ngModel)]="idsOtrosSelecionados"
      [ngModelOptions]="{ standalone: true }"
      [hideSelected]="true"
      placeholder="Otros Botones"
      [multiple]="true"
      [searchFn]="searchBoton"
      [closeOnSelect]="false"
      bindValue="_id"
      bindLabel="nombre"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>
    </ng-select>

    <!-- Orden Otros -->
    <div
      *ngIf="idsOtrosSelecionados?.length"
      cdkDropList
      class="list"
      (cdkDropListDropped)="dropOtros($event)"
    >
      <div
        class="box"
        *ngFor="let id of idsOtrosSelecionados; let i = index"
        cdkDrag
      >
        <div fxLayout="row" fxFill fxLayoutGap="12px">
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
          <mat-icon
            cdkDragHandle
            color="accent"
            [ngClass]="{ 'cursor-move': true }"
            >drag_indicator</mat-icon
          >
          <span>{{ i + 1 }}</span>
          <span>{{ btnName(id) || id }}</span>
        </div>
      </div>
    </div>

    <!-- 3er Boton de Control RF -->
    <!-- [(ngModel)]="idsOtrosSelecionados"
    [ngModelOptions]="{ standalone: true }" -->
    <ng-select
      [items]="botones"
      formControlName="idBotonAlertaControl"
      [hideSelected]="true"
      placeholder="Tercer Botón de Control genera Alerta"
      [searchFn]="searchBoton"
      [multiple]="false"
      [closeOnSelect]="true"
      bindValue="_id"
      bindLabel="nombre"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <span>
          {{ item.nombre }}
          {{ item.variante ? "(" + item.variante + ")" : "" }}
        </span>
      </ng-template>
    </ng-select>

    <!-- <mat-form-field>
      <mat-label>Tercer Botón de Control genera Alerta</mat-label>
      <mat-select formControlName="idBotonAlertaControl">
        <mat-option *ngFor="let dato of botones" [value]="dato._id">
          {{ dato.nombre }} {{ dato.variante ? "(" + dato.variante + ")" : "" }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="formulario?.get('idBotonAlertaControl')?.value"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="
          $event.stopPropagation();
          formulario.patchValue({ idBotonAlertaControl: null })
        "
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field> -->
    <!-- Botón 1 -->
    <div class="row-center" style="gap: 8px">
      <section class="layout">
        <div>
          <span> Botón 1 (A): </span>
        </div>
        <div class="grow1">
          <mat-form-field>
            <mat-label>Acción </mat-label>
            <mat-select
              [value]="boton1"
              (selectionChange)="updateBoton(1, $event.value)"
            >
              @for (a of acciones; track a) {
                <mat-option [value]="a">{{ a }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (boton1 === "Alerta") {
          <div class="grow1">
            <ng-select
              placeholder="Alerta"
              formControlName="boton1"
              appendTo="body"
            >
              @for (b of botones; track b._id) {
                <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
              }
            </ng-select>
          </div>
        }
      </section>
    </div>
    <!-- Botón 2 -->
    <div class="row-center" style="gap: 8px">
      <section class="layout">
        <div><span>Botón 2 (B):</span></div>
        <div class="grow1">
          <mat-form-field>
            <mat-label>Acción </mat-label>
            <mat-select
              [value]="boton2"
              (selectionChange)="updateBoton(2, $event.value)"
            >
              @for (a of acciones; track a) {
                <mat-option [value]="a">{{ a }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (boton2 === "Alerta") {
          <div class="grow1">
            <ng-select
              placeholder="Alerta"
              formControlName="boton2"
              appendTo="body"
            >
              @for (b of botones; track b._id) {
                <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
              }
            </ng-select>
          </div>
        }
      </section>
    </div>
    <!-- Botón 3 -->
    <div class="row-center" style="gap: 8px">
      <section class="layout">
        <div><span>Botón 3 (C):</span></div>
        <div class="grow1">
          <mat-form-field>
            <mat-label>Acción </mat-label>
            <mat-select
              [value]="boton1"
              (selectionChange)="updateBoton(3, $event.value)"
            >
              @for (a of acciones; track a) {
                <mat-option [value]="a">{{ a }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (boton3 === "Alerta") {
          <div class="grow1">
            <ng-select
              placeholder="Alerta"
              formControlName="boton3"
              appendTo="body"
            >
              @for (b of botones; track b._id) {
                <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
              }
            </ng-select>
          </div>
        }
      </section>
    </div>

    <div class="mb-2">
      <mat-checkbox formControlName="twitter">Mostrar Twitter</mat-checkbox>
    </div>
  </form>

  <div fxLayoutGap="10px" style="text-align: end">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="volver()">Volver</button>
  </div>
</div>
