import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IActualizacionFirmware,
  IEstadoSirena,
  IEventoSirena,
  IFilter,
  IListado,
  ILogSirena,
  IQueryParam,
  ISirena,
  ISocketMessage,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { SirenasService } from '../sirenas.service';
import {
  Options,
  SeriesXrangeOptions,
  XrangePointOptionsObject,
} from 'highcharts';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import * as moment from 'moment';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditarSirenasComponent } from '../editar-sirenas/editar-sirenas.component';
import { QueryFilterService } from '../../../auxiliares/servicios/queryFilter.service';

@Component({
  selector: 'app-detalles-sirena',
  templateUrl: './detalles-sirena.component.html',
  styleUrls: ['./detalles-sirena.component.scss'],
})
export class DetallesSirenaComponent implements OnInit {
  public loading = false;
  public id?: string | null;
  public sirena?: ISirena;
  public actualizacionFirm?: IActualizacionFirmware;

  public estados?: IEstadoSirena[] = [];
  public eventos?: IEventoSirena[] = [];
  public logs?: ILogSirena[] = [];

  public mapOptions: google.maps.MapOptions = {
    zoom: 16,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.TOP_LEFT,
    },
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  public chartOptions?: Options;

  // Listado Continuo
  private datos$?: Subscription;
  private eventos$?: Subscription;
  private estados$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private service: SirenasService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    public matDialog: MatDialog
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  // Grafico

  private createChart() {
    const series: SeriesXrangeOptions[] = [
      {
        type: 'xrange',
        data: [],
      },
    ];
    const tresHoras = 3 * 60 * 60 * 1000;
    this.estados?.forEach((estado) => {
      const inicio = new Date(estado.fechaOnline!).getTime();
      const fin = new Date(estado.fechaOffline || new Date()).getTime();
      const data: XrangePointOptionsObject = {
        color: 'green',
        x: inicio - tresHoras,
        x2: fin - tresHoras,
        y: 0,
      };
      series[0].data!.push(data);
    });

    this.eventos?.forEach((evento) => {
      const inicio = new Date(evento.fechaEncendido!).getTime();
      const fin = new Date(evento.fechaApagado || inicio + 30 * 1000).getTime();
      const data: XrangePointOptionsObject = {
        color: evento.tipo === 'Sirena' ? 'red' : 'blue',
        x: inicio - tresHoras,
        x2: fin - tresHoras,
        y: evento.tipo === 'Sirena' ? 1 : 2,
      };
      series[0].data!.push(data);
    });

    const options: Options = {
      chart: {
        type: 'xrange',
        zooming: {
          mouseWheel: false,
        },
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        xrange: {
          minPointLength: 2,
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              function msToTime(ms: number): string {
                if (+ms) {
                  const d = moment.duration(ms);
                  const hours = Math.floor(d.asHours());
                  if (hours) {
                    return `${hours}h ${d.minutes()}m`;
                  } else {
                    const minutes = Math.floor(d.asMinutes()) - hours * 60;
                    if (minutes) {
                      return `${minutes}m ${d.seconds()}s`;
                    } else {
                      const seconds = Math.floor(d.asSeconds()) - minutes * 60;
                      return `${seconds}s`;
                    }
                  }
                }
                return 'Sin tiempo';
              }
              const tresHoras = 3 * 60 * 60 * 1000;
              const categoria = this.series.chart.yAxis[0].categories[this.y!];
              const ms = ((this as any).x2 as number) - this.x;
              return `
                <strong> ${categoria} por ${msToTime(ms)} </strong>
                <br>
                <br>
                <span style="color: ${this.color}">●</span> 
                ${new Date(this.x + tresHoras).toLocaleString()} - 
                ${new Date(
                  ((this as any).x2 as number) + tresHoras
                ).toLocaleString()}`;
            },
          },
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: '',
        },
        categories: ['Online', 'Sirena Encendida', 'Reflector Encendido'],
        reversed: true,
      },
      series,
    };
    this.chartOptions = options;
  }

  // Acciones

  public async actualizarFirmware(dato: ISirena): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Enviar comando de actualización de firmware?`
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service
          .actualizarFirmware(dato.chipId!)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Comando enviado');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async reset(dato: ISirena): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Enviar comando de reset?`
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.reset(dato.chipId!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Comando enviado');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async editar(data: ISirena) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(EditarSirenasComponent, config);
  }
  public async eliminar(dato: ISirena): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la sirena?`
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  // Listados
  private async listar(): Promise<void> {
    if (this.id) {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<ISirena>('sirena', this.id)
        .subscribe((data) => {
          this.sirena = data;
          console.debug(new Date().toLocaleString(), `listado de sirena`, data);
        });
      await this.listadosService.getLastValue('sirena', this.id);
    }
  }

  private async listarEventos(): Promise<void> {
    // Filtro
    const filter: IFilter<IEventoSirena> = {
      chipId: this.sirena?.chipId,
      fechaEncendido: {
        $gte: new Date(
          new Date().getTime() - 48 * 60 * 60 * 1000
        ).toISOString(),
      },
    };

    const query: IQueryParam = {
      sort: 'fechaEncendido',
      filter: JSON.stringify(filter),
    };

    // Query
    this.eventos$?.unsubscribe();
    this.eventos$ = this.listadosService
      .subscribe<IListado<IEventoSirena>>('eventosSirenas', query)
      .subscribe((data) => {
        this.eventos = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de eventosSirenas`,
          data
        );
      });
    await this.listadosService.getLastValue('eventosSirenas', query);
  }

  private async listarEstados(): Promise<void> {
    // Filtro
    const filter: IFilter<ISirena> = {
      chipId: this.sirena?.chipId,
      fechaOnline: {
        $gte: new Date(
          new Date().getTime() - 48 * 60 * 60 * 1000
        ).toISOString(),
      },
    };
    const query: IQueryParam = {
      sort: 'fechaOnline',
      filter: JSON.stringify(filter),
    };

    // Query
    this.estados$?.unsubscribe();
    this.estados$ = this.listadosService
      .subscribe<IListado<IEstadoSirena>>('estadosSirenas', query)
      .subscribe((data) => {
        this.estados = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de estadosSirenas`,
          data
        );
      });
    await this.listadosService.getLastValue('estadosSirenas', query);
  }

  // ################################################################################

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await this.listar();
    await Promise.all([this.listarEventos(), this.listarEstados()]);
    this.createChart();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
    this.eventos$?.unsubscribe();
    this.estados$?.unsubscribe();
  }
}
