import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { VecinosRoutingModule } from './vecinos.routing.module';
import { ListarVecinosComponent } from './listar-vecinos/listar-vecinos.component';
import { CrearEditarVecinoComponent } from './crear-editar-vecino/crear-editar-vecino.component';
import { GraficosVecinosComponent } from './graficos-vecinos/graficos-vecinos.component';
import { CartaVecinoComponent } from './listar-vecinos/carta-vecino/carta-vecino.component';
import { ConfigVecinoComponent } from './listar-vecinos/carta-vecino/config-vecino/config-vecino.component';

@NgModule({
  declarations: [
    ListarVecinosComponent,
    CrearEditarVecinoComponent,
    GraficosVecinosComponent,
    CartaVecinoComponent,
    ConfigVecinoComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, VecinosRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VecinosModule {}
