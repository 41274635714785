import { Component, Input } from '@angular/core';
import { IConfigVecino } from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { VecinosService } from '../../vecinos.service';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrearEditarVecinoComponent } from '../../crear-editar-vecino/crear-editar-vecino.component';

@Component({
  selector: 'app-carta-vecino',
  templateUrl: './carta-vecino.component.html',
  styleUrls: ['./carta-vecino.component.scss'],
})
export class CartaVecinoComponent {
  public loading = false;
  @Input() public config?: IConfigVecino;

  constructor(
    private vecinosService: VecinosService,
    private helper: HelperService,
    private dialogService: DialogService,
    public matDialog: MatDialog,
  ) {}

  public getModoRegistro(config: IConfigVecino) {
    if (config.creadoPorAdmin && !config.importado) {
      return 'Creado por Admin';
    }
    if (config.importado) {
      return 'Importado';
    }
    if (!config.creadoPorAdmin) {
      if (config.dniEscaneado) {
        return 'Creado por Vecino (DNI)';
      } else if (config.dniEscaneado === false) {
        return 'Creado por Vecino (Manual)';
      } else {
        return 'Creado por Vecino';
      }
    }
    return 'Desconocido';
  }

  /// ACCIONES

  public async eliminar(dato: IConfigVecino): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el vecino ${dato.datosPersonales?.nombre}? El mismo se podrá volver a registrar`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await firstValueFrom(this.vecinosService.eliminarConfig(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  public async desloguear(dato: IConfigVecino): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desloguear al vecino ${dato.datosPersonales?.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        if (!dato.datosPersonales?.telefono) {
          throw new Error('El vecino no tiene teléfono');
        }
        await firstValueFrom(
          this.vecinosService.desloguear(dato.datosPersonales?.telefono),
        );
        this.helper.notifSuccess('Logout correcto');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  public async editar(data: IConfigVecino): Promise<void> {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarVecinoComponent, config);
  }
}
