import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { UsuariosRoutingModule } from './usuarios.routing.module';
import { ListarUsuariosComponent } from './listar-usuarios/listar-usuarios.component';
import { CrearEditarUsuarioComponent } from './crear-editar-usuario/crear-editar-usuario.component';

@NgModule({
  declarations: [
    ListarUsuariosComponent,
    CrearEditarUsuarioComponent
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    UsuariosRoutingModule,
  ]
})
export class UsuariosModule { }
