<div
  style="display: flex; justify-content: start; align-items: center"
  [style.gap]="(isHandset$ | async) ? '0.5em' : '1em'"
>
  <!-- Input Search -->
  <form *ngIf="search" autocomplete="off" style="flex-grow: 1">
    <mat-form-field class="no-hint" style="width: 100%">
      <mat-label>Buscar</mat-label>
      <input
        matInput
        type="search"
        #inputSearch
        placeholder="Buscar..."
        [(ngModel)]="search.value"
        [ngModelOptions]="{ standalone: true }"
      />
      <button
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="search.value = ''; cambioFiltro()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>

  <div *ngIf="datos?.length">
    <!-- Boton Filtro -->
    <div>
      <!-- <div class="filter-cnt"> -->
      <button
        mat-button
        [matMenuTriggerFor]="filterMenu"
        [ngStyle]="{ color: filtroActivo ? 'red' : 'unset' }"
      >
        <mat-icon>filter_alt</mat-icon>
        <span>{{ (isHandset$ | async) ? "" : "Filtro" }}</span>
      </button>
    </div>

    <!-- Elementos Menu Filtro -->
    <mat-menu #filterMenu="matMenu" class="filtro-tabla">
      <div (click)="$event.stopPropagation()" style="padding: 1em">
        <p style="text-align: right; font-weight: 500">
          <span (click)="limpiarFiltros()" style="cursor: pointer">
            Limpiar filtros
          </span>
        </p>
        <form autocomplete="off">
          <ng-container *ngFor="let dato of datos; let i = index">
            <!-- Select Object -->
            <mat-form-field
              *ngIf="
                dato.tipo === 'select' && dato.selectValue && dato.selectLabel
              "
            >
              <mat-label>{{ dato.label }}</mat-label>
              <mat-select
                [name]="dato.label"
                [(ngModel)]="dato.filter.value"
                (selectionChange)="cambioFiltro()"
              >
                <mat-option
                  *ngFor="let elemento of dato.elementos"
                  [value]="elemento[dato.selectValue]"
                  >{{ elemento[dato.selectLabel] }}</mat-option
                >
              </mat-select>
              <button
                *ngIf="dato.filter.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="
                  dato.filter.value = undefined;
                  $event.stopPropagation();
                  cambioFiltro()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- Select String[] -->
            <mat-form-field
              *ngIf="
                dato.tipo === 'select' && !dato.selectValue && !dato.selectLabel
              "
            >
              <mat-label>{{ dato.label }}</mat-label>
              <mat-select
                [name]="dato.label"
                [(ngModel)]="dato.filter.value"
                (selectionChange)="cambioFiltro()"
              >
                <mat-option
                  *ngFor="let elemento of dato.elementos"
                  [value]="elemento"
                  >{{ elemento }}</mat-option
                >
              </mat-select>
              <button
                *ngIf="dato.filter.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="
                  dato.filter.value = undefined;
                  $event.stopPropagation();
                  cambioFiltro()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- Input -->
            <mat-form-field *ngIf="dato.tipo === 'input'">
              <mat-label>{{ dato.label }}</mat-label>
              <input
                matInput
                type="search"
                [name]="dato.label"
                [(ngModel)]="dato.filter.value"
                (ngModelChange)="cambioFiltro()"
              />
            </mat-form-field>

            <!-- Input -->
            <mat-form-field *ngIf="dato.tipo === 'mongo'">
              <mat-label>{{ dato.label }}</mat-label>
              <input
                matInput
                type="search"
                [placeholder]="placeholder"
                [name]="dato.label"
                [(ngModel)]="dato.filter.value"
                (ngModelChange)="cambioFiltro()"
              />
            </mat-form-field>

            <!-- Date Range -->
            <mat-form-field *ngIf="dato.tipo === 'dateRange'">
              <mat-label>{{ dato.label }}</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  placeholder="Desde"
                  [name]="dato.label"
                  [(ngModel)]="dato.desde"
                  (ngModelChange)="cambioFiltro()"
                />
                <input
                  matEndDate
                  placeholder="Hasta"
                  [name]="dato.label"
                  [(ngModel)]="dato.hasta"
                  (ngModelChange)="cambioFiltro()"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <!-- Ng-select async -->
            <ng-select
              *ngIf="dato.tipo === 'ngselect-async'"
              [appearance]="appearance"
              [items]="asyncData$![i] | async"
              [typeahead]="asyncInput$![i]"
              [minTermLength]="3"
              [placeholder]="dato.label"
              [bindLabel]="dato.selectLabel!"
              [bindValue]="dato.selectValue!"
              [minTermLength]="3"
              loadingText="Cargando..."
              typeToSearchText="Buscar..."
              notFoundText="No se encontraron resultados"
              appendTo="body"
              (change)="dato.filter.value ? cambioFiltro() : {}"
              [(ngModel)]="dato.filter.value"
              [ngModelOptions]="{ standalone: true }"
              (clear)="dato.filter.value = undefined; cambioFiltro()"
            >
            </ng-select>

            <!-- Ng-select -->
            <ng-select
              *ngIf="dato.tipo === 'ngselect'"
              [appearance]="appearance"
              [items]="dato.elementos!"
              [placeholder]="dato.label"
              [bindLabel]="dato.selectLabel!"
              [bindValue]="dato.selectValue!"
              loadingText="Cargando..."
              typeToSearchText="Buscar..."
              notFoundText="No se encontraron resultados"
              appendTo="body"
              (change)="dato.filter.value ? cambioFiltro() : {}"
              [(ngModel)]="dato.filter.value"
              [ngModelOptions]="{ standalone: true }"
              [multiple]="!!dato.multiple"
              [closeOnSelect]="!dato.multiple"
              style="min-width: 200px"
              (clear)="dato.filter.value = undefined; cambioFiltro()"
            >
            </ng-select>

            <!-- RegExp -->
            <mat-form-field *ngIf="dato.tipo === 'regexp'">
              <mat-label>{{ dato.label }}</mat-label>
              <input
                #inputSearch
                matInput
                type="search"
                [placeholder]="dato.label"
                [(ngModel)]="dato.filter.value"
                [ngModelOptions]="{ standalone: true }"
              />
              <button
                *ngIf="dato.filter.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="
                  dato.filter.value = undefined;
                  $event.stopPropagation();
                  cambioFiltro()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </ng-container>
        </form>
      </div>
    </mat-menu>
  </div>
</div>
