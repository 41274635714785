import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ICliente,
  IControl,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { ControlesService } from '../controles.service';
import { CrearEditarControlesComponent } from '../crear-editar-controles/crear-editar-controles.component';

declare type mainType = IControl;

@Component({
  selector: 'app-listado-controles',
  templateUrl: './listado-controles.component.html',
  styleUrls: ['./listado-controles.component.scss'],
})
export class ListadoControlesComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['chipId', 'etiqueta'],
  };

  public vecino: IFiltroTabla = {
    filter: {
      field: 'idVecino',
    },
    label: 'Vecino',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarVecinos$',
    searchOn: ['nombre', 'dni'],
  };

  public baneado: IFiltroTabla = {
    elementos: [
      { nombre: 'Baneado', _id: true },
      { nombre: 'No Baneado', _id: false },
    ],
    filter: {
      field: 'baneado',
    },
    label: 'Baneado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };

  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public fecha: IFiltroTabla = {
    label: 'Rango de Fechas',
    filter: {
      field: 'fechaCreacion',
    },
    tipo: 'dateRange',
  };

  @Input() loading?: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public columnas = [
    'etiqueta',
    'chipId',
    'baneado',
    'fechaCreacion',
    'idConfigVecino',
    'cliente',
    'acciones',
  ];
  public nombreColumnas = [
    'Etiqueta',
    'ID',
    'Baneado',
    'Fecha Creación',
    'Vecino',
    'Cliente',
    'Acciones',
  ];
  public saveColumnas = 'tabla-controles';
  public dataSource = new MatTableDataSource<mainType>([]);
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  private populate = [
    {
      path: 'vecino',
      select: 'nombre',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'configVecino',
      select: 'datosPersonales idCliente',
      populate: {
        path: 'cliente',
        select: 'nombre',
      },
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fechaCreacion',
    populate: JSON.stringify(this.populate),
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  private clientes$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: ControlesService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  private onLoadingChange(loading: boolean): void {
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<unknown>) {
    this.onLoadingChange(true);
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.onLoadingChange(false);
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearEditarControlesComponent, config);
  }
  public async editar(data: mainType) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarControlesComponent, config);
  }
  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el control?`,
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }

  // Listados
  public async actualizar(): Promise<void> {
    this.onLoadingChange(true);
    await this.listar();
    this.onLoadingChange(false);
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IControl>>('controles', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(
          new Date().toLocaleString(),
          `listado de controles`,
          data,
        );
      });
    await this.listadosService.getLastValue('controles', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.actualizar(), this.listarClientes()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
