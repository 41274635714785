/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  FuncionBoton,
  IBoton,
  ICategoria,
  ICreateBoton,
  tipoDato,
} from 'modelos/src';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { BotonesService } from '../botones.service';
import { firstValueFrom } from 'rxjs';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-crear-editar-boton',
  templateUrl: './crear-editar-boton.component.html',
  styleUrls: ['./crear-editar-boton.component.scss'],
})
export class CrearEditarBotonComponent implements OnInit {
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;

  public id: string | null = null;
  public data?: IBoton;
  public notificaciones = ['Sirena', 'Campana', 'Silencio'];

  public categorias?: ICategoria[];

  public funciones: FuncionBoton[] = [
    'Alerta',
    'Evento',
    'Sirena',
    'Reflector',
    '911',
    'Link',
    'Alerta por Punto',
  ];

  public tiposDatos: tipoDato[] = [
    'Texto',
    'Texto Largo',
    'Numero',
    'Fecha',
    'Booleano',
    'Ubicación',
    'Foto',
    'Persona',
    'Lista de personas',
    'Texto (Requerido)',
    'Texto Largo (Requerido)',
    'Numero (Requerido)',
    'Fecha (Requerido)',
    'Booleano (Requerido)',
    'Ubicación (Requerido)',
    'Foto (Requerido)',
    'Persona (Requerido)',
    'Lista de personas (Requerido)',
  ];

  public tiposDePunto = ['Escuela'];

  // Preserve original property order
  public originalOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>,
  ): number => {
    return 0;
  };

  public get formularioDinamico(): UntypedFormGroup {
    return this.formulario?.get('formulario') as UntypedFormGroup;
  }

  public get formularioTrackeo(): UntypedFormGroup {
    return this.formulario?.get('trackeo') as UntypedFormGroup;
  }

  public get formularioEnvioMultimedia(): UntypedFormGroup {
    return this.formulario?.get('envioMultimedia') as UntypedFormGroup;
  }

  public get imagenes(): UntypedFormGroup {
    return this.formulario?.get('imagenes') as UntypedFormGroup;
  }

  public get categoriasCliente(): UntypedFormArray {
    return this.formulario?.get('categorias') as UntypedFormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private service: BotonesService,
    public helper: HelperService,
    private route: ActivatedRoute,
  ) {}

  private crearFormulario(): void {
    const formFormulario = this.fb.group({});
    if (this.data?.formulario) {
      Object.entries(this.data.formulario).forEach(([label, tipo]) => {
        formFormulario.addControl(
          label,
          this.fb.control(tipo, Validators.required),
        );
      });
    }

    this.formulario = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      color: [this.data?.color],
      variante: [this.data?.variante],
      funcion: [this.data?.funcion],
      texto: [this.data?.texto],
      urlImagen: [this.data?.urlImagen],
      urlImagenPush: [this.data?.urlImagenPush],
      sirena: [this.data?.sirena],
      global: [this.data?.global],
      notificacion: [this.data?.notificacion],
      codigoSmartCity: [this.data?.codigoSmartCity],
      link: [this.data?.link],
      finalizacionAutomatica: [this.data?.finalizacionAutomatica],
      tipoDePunto: [this.data?.tipoDePunto],
      requierePreAprobacion: [this.data?.requierePreAprobacion],

      // Formulario dinamico dependiendo del tipo de evento
      label: [],
      tipo: [],
      formulario: formFormulario,

      // Trackeo
      trackeo: this.fb.group({
        // El viejo
        intervaloSegundos: [this.data?.trackeo?.intervaloSegundos || 0],
        tiempoMinutos: [this.data?.trackeo?.tiempoMinutos || 0],
        // El nuevo
        gps: [this.data?.trackeo?.gps],
        gpsTiempo: [this.data?.trackeo?.gpsTiempo],
        gpsIntervalo: [this.data?.trackeo?.gpsIntervalo],
        foto: [this.data?.trackeo?.foto],
        fotoCantidad: [this.data?.trackeo?.fotoCantidad],
        fotoIntervalo: [this.data?.trackeo?.fotoIntervalo],
        audio: [this.data?.trackeo?.audio],
        audioTiempo: [this.data?.trackeo?.audioTiempo],
        video: [this.data?.trackeo?.video],
        videoTiempo: [this.data?.trackeo?.videoTiempo],
      }),
      // Envio Multimedia
      envioMultimedia: this.fb.group({
        audio: [this.data?.envioMultimedia?.audio],
        video: [this.data?.envioMultimedia?.video],
        foto: [this.data?.envioMultimedia?.foto],
        gps: [this.data?.envioMultimedia?.gps],
      }),
    });
  }

  public agregarCampo(): void {
    const label = this.formulario?.get('label')?.value;
    const tipo = this.formulario?.get('tipo')?.value;
    if (label && tipo) {
      this.formularioDinamico?.addControl(
        label,
        this.fb.control(tipo, Validators.required),
      );
    }
    this.formulario?.patchValue({ label: '' });
  }

  public eliminarCampo(label: string): void {
    this.formularioDinamico?.removeControl(label);
  }

  public volver(): void {
    window.history.back();
  }

  //

  private getData(): ICreateBoton {
    const data: ICreateBoton = this.formulario?.value;

    delete (data as any).label;
    delete (data as any).tipo;

    return data;
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  private async listar(id: string): Promise<void> {
    this.data = await firstValueFrom(this.service.listarPorId(id));
  }

  public async setFile(event: Event, formControlName: string): Promise<void> {
    this.loading = true;
    const file = (event as any).target?.files?.item(0);
    const nombreBoton = this.formulario?.get('nombre')?.value;
    const variante = this.formulario?.get('variante')?.value;
    const fileName = `botones/${nombreBoton}/${variante}/${formControlName}`;

    const res = await firstValueFrom(
      this.service.subirImagenPublica(file, fileName),
    );

    const val = res.url + `?${(Math.random() * 1000).toFixed(0)}`;
    this.formulario?.patchValue({ [formControlName]: val });
    this.loading = false;
  }

  public nombresValido(): boolean {
    const nombre = this.formulario?.get('nombre')?.value;
    const variante = this.formulario?.get('variante')?.value;
    if (nombre && variante) {
      return true;
    } else {
      return false;
    }
  }

  public onColorChange(event: string, control: string) {
    this.formulario?.get(control)?.patchValue(event);
  }

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    if (this.id) {
      await this.listar(this.id);
    }
    this.crearFormulario();
  }
}
