import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment, VERSION, ENV } from 'src/environments/environment';
import { ChangelogComponent } from '../../../auxiliares/changelog/changelog.component';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { PwaService } from '../../../auxiliares/servicios/pwa.service';
import { WebSocketService } from '../../../auxiliares/servicios/websocket';
import { CrearControlesComponent } from '../../controles/crear-controles/crear-controles.component';
import { AuthService } from '../../login/auth.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public version = VERSION;
  public env = ENV;
  public production = environment.production;
  public iniciales = 'XX';
  public usuario?: string;
  public roles?: string[];
  public mobileQuery: MediaQueryList;

  constructor(
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialogService: DialogService,
    public auth: AuthService,
    private wss: WebSocketService,
    public pwa: PwaService,
    private matDialog: MatDialog,
    public helper: HelperService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  public abrirChangelog() {
    const config: MatDialogConfig = {
      width: '800px',
    };
    this.matDialog.open(ChangelogComponent, config);
  }

  private changelog() {
    const noMostrar = localStorage.getItem(`changelog-${VERSION}`);
    if (!noMostrar) {
      this.abrirChangelog();
    }
  }

  public salir(): void {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea cerrar la sesión?')
      .then((confirm) => {
        if (confirm) {
          this.wss.closeWS();
          this.router.navigateByUrl('login');
        }
      });
  }

  public async crearControl(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearControlesComponent, config);
  }

  private cargarUsuario() {
    const usuario = this.auth.getUsuario();
    this.usuario = usuario.usuario;
    this.roles = usuario.roles;
    const nombreCompleto = usuario.nombre;
    const nombreArray = nombreCompleto?.split(' ');
    const nombre = nombreArray?.[0] || '';
    const apellido = nombreArray?.[nombreArray?.length - 1] || '';
    this.iniciales = nombre?.substr(0, 1) + apellido?.substr(0, 1);
  }

  ngOnInit(): void {
    this.cargarUsuario();
    this.changelog();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }
}
