import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IFilter, IQueryParam } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class QueryFilterService {
  private querys: { [path: string]: IQueryParam } = {};

  constructor(private router: Router) {}

  public setQuery(
    activatedRoute: ActivatedRoute,
    path: string,
    query: IQueryParam
  ) {
    const saveQuery: IQueryParam = {
      filter: query.filter,
      sort: query.sort,
      limit: query.limit,
      page: query.page,
    };
    this.querys[path] = saveQuery;
    const queryParams: Params = {
      queryParams: JSON.stringify(saveQuery),
    };
    this.router.navigate([], {
      relativeTo: activatedRoute,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  public getQueryParam(path: string) {
    return { queryParams: JSON.stringify(this.querys[path]) };
  }

  public getQuery(qp: Params) {
    const response: {
      queryParams?: IQueryParam;
      filter?: IFilter<any>;
      sortDirection?: 'asc' | 'desc';
      sortActive?: string;
    } = {};
    const queryParams = qp.queryParams;
    if (queryParams) {
      response.queryParams = JSON.parse(queryParams);
      if (response.queryParams?.filter) {
        response.filter = JSON.parse(response.queryParams.filter);
      }
      if (response.queryParams?.sort) {
        const arr = response.queryParams.sort.split('-');
        if (arr.length === 2) {
          response.sortDirection = 'desc';
          response.sortActive = arr[1];
        } else {
          response.sortDirection = 'asc';
          response.sortActive = arr[0];
        }
      }
    }
    return response;
  }
}
