import { Injectable } from '@angular/core';
import {
  ICreateMantenimiento,
  IListado,
  IMantenimiento,
  IQueryParam,
  IUpdateMantenimiento,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { HttpApiAdminService } from 'src/app/auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class MantenimientosService {
  constructor(public http: HttpApiAdminService, public helper: HelperService) {}

  public getFiltered(
    queryParams?: IQueryParam
  ): Observable<IListado<IMantenimiento>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/mantenimientos`, { params });
  }

  public getById(id: string): Observable<IMantenimiento> {
    return this.http.get(`/mantenimientos/${id}`);
  }

  public create(dato: ICreateMantenimiento): Observable<IMantenimiento> {
    return this.http.post(`/mantenimientos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateMantenimiento
  ): Observable<IMantenimiento> {
    return this.http.put(`/mantenimientos/${id}`, dato);
  }

  public delete(id: string): Observable<IMantenimiento> {
    return this.http.delete(`/mantenimientos/${id}`);
  }
}
