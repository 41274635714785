<div id="map" class="map-container">
  <div id="flotante" class="ol-flotante ac-85">
    <app-flotante
      [sirenas]="sirenas.length"
      (geocode)="addMarker($event)"
      [loading]="loading()"
      (clientesChange)="clientesChange($event)"
      (estadoChange)="estadoChange($event)"
      (mostrarClientesChange)="mostrarClientesChange($event)"
      (mostrarSirenasChange)="mostrarSirenasChange($event)"
    ></app-flotante>
  </div>
  @if (sirena) {
    <div class="ol-isla">
      <app-isla [sirena]="sirena" (cerrarChange)="cerrar($event)"></app-isla>
    </div>
  }
</div>
