import { Injectable } from '@angular/core';
import {
  ICrearFirmwareVersion,
  IFirmwareVersion,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class FirmwareVersionesService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<IFirmwareVersion>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/firmwareVersiones`, { params });
  }

  public listarPorId(id: string): Observable<IFirmwareVersion> {
    return this.http.get(`/firmwareVersiones/${id}`);
  }

  public crear(dato: ICrearFirmwareVersion): Observable<IFirmwareVersion> {
    return this.http.post(`/firmwareVersiones`, dato);
  }

  public eliminar(id: string): Observable<IFirmwareVersion> {
    return this.http.delete(`/firmwareVersiones/${id}`);
  }
}
