<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Pórticos </span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Portico Crear
    <button mat-fab matTooltip="Crear" color="primary" [routerLink]="'crear'">
      <mat-icon>add</mat-icon>
    </button> -->
    <button
      class="mx-1 no-shadow"
      mat-fab
      color="accent"
      matTooltip="Lecturas"
      [routerLink]="['lecturas']"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna mac address  -->
    <ng-container matColumnDef="macAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        MAC Address
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">MAC Address:</span>
        {{ row.macAddress }}
      </mat-cell>
    </ng-container>

    <!-- Columna nombre  -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre:</span>
        {{ row.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna online  -->
    <ng-container matColumnDef="online">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Online
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Estado:</span>

        <!-- ONLINE -->
        <span
          [tooltip]="$any(tooltipOnline)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <mat-icon
            *ngIf="row.online"
            style="align-items: center"
            [style.color]="'green'"
          >
            wifi
          </mat-icon>
        </span>
        <ng-template #tooltipOnline>
          <div style="text-align: left">
            <h3>ONLINE</h3>
            <p>Ip: {{ row.conexion?.ip }}</p>
            <p>Mask: {{ row.conexion?.mask }}</p>
            <p>Gateway: {{ row.conexion?.gateway }}</p>
            <p>dns: {{ row.conexion?.dns }}</p>
          </div>
        </ng-template>

        <!-- OFFLINE -->
        <span
          [tooltip]="$any(tooltipOffline)"
          contentType="template"
          theme="light"
          maxWidth="500px"
          style="padding-bottom: 12px"
        >
          <mat-icon
            *ngIf="!row.online"
            style="align-items: center"
            [style.color]="row.actualizando ? 'yellow' : 'red'"
          >
            wifi_off
          </mat-icon>
        </span>
        <ng-template #tooltipOffline>
          <div style="text-align: left">
            <h3>OFFLINE</h3>
            <p>Ip: {{ row.conexion?.ip }}</p>
            <p>Mask: {{ row.conexion?.mask }}</p>
            <p>Gateway: {{ row.conexion?.gateway }}</p>
            <p>dns: {{ row.conexion?.dns }}</p>
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- Columna cliente  -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente actual </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente actual:</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span> {{ row.cliente?.nombre | titlecase }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna fechaColocacion  -->
    <ng-container matColumnDef="fechaColocacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha Colocacion
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha de Colocación:</span>
        {{ row.fechaColocacion | date: "dd/MM/yyyy" }}
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <div>
          <button
            class="mx-1 no-shadow"
            mat-mini-fab
            color="accent"
            matTooltip="Lecturas pórtico"
            [routerLink]="['lecturas', row._id]"
          >
            <mat-icon>search</mat-icon>
          </button>
          <button
            class="mx-1 no-shadow"
            mat-mini-fab
            color="accent"
            matTooltip="Editar"
            [routerLink]="['editar', row._id]"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            class="mx-1 no-shadow"
            mat-mini-fab
            color="warn"
            matTooltip="Eliminar"
            (click)="eliminar(row)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
