import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExportarComponent } from './exportar/exportar.component';

const routes: Routes = [{ path: '', component: ExportarComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExportarRoutingModule {}
