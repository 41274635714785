<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Vecinos </span></h2>

<app-graficos-vecinos></app-graficos-vecinos>

<!-- LISTADO CON CARTAS -->
<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="filtros"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Boton XLS -->
    <button mat-fab matTooltip="Exportar" color="primary" (click)="exportar()">
      XLS
    </button>
  </div>

  <!-- Paginator -->
  <mat-paginator
    #topPaginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <div class="grid" *ngIf="!loading">
    <div *ngFor="let c of datos">
      <app-carta-vecino *ngIf="c" [config]="c"></app-carta-vecino>
    </div>
  </div>

  <div *ngIf="loading" class="spinner">
    <mat-spinner></mat-spinner>
  </div>

  <!-- Paginator -->
  <mat-paginator
    #botPaginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>
