import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISirena } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';

@Component({
  selector: 'app-isla',
  templateUrl: './isla.component.html',
  styleUrl: './isla.component.scss',
})
export class IslaComponent {
  @Input() sirena?: ISirena & { estado: string };
  @Output() cerrarChange = new EventEmitter<boolean>();

  constructor(public helper: HelperService) {}

  public cerrar(): void {
    this.cerrarChange.emit(true);
  }
}
