import { v } from './version';

export const environment = {
  production: true,
};
export const ENV = 'test';
export const VERSION = v;
export const NOMBRE_APP = 'Admin';
// Apis
export const apiAdmin = 'https://apis2.novit.gpesistemas.ar/admin-test';
export const websocketServer =
  'wss://apis2.novit.gpesistemas.ar/websocket-test';
