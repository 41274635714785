import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  ICliente,
  IConfigVecino,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IVecino,
} from 'modelos/src';

import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { VecinosService } from '../vecinos.service';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrearEditarVecinoComponent } from '../crear-editar-vecino/crear-editar-vecino.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';

@Component({
  selector: 'app-listar-vecinos',
  templateUrl: './listar-vecinos.component.html',
  styleUrls: ['./listar-vecinos.component.scss'],
})
export class ListarVecinosComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public audio?: HTMLAudioElement;
  public godMode = false;

  @ViewChild('topPaginator') top?: MatPaginator;
  @ViewChild('botPaginator') bottom?: MatPaginator;

  public search: IRegExpSearch = {
    fields: [
      'datosPersonales.nombre',
      'datosPersonales.dni',
      'datosPersonales.email',
      'datosPersonales.telefono',
      'direccion.direccion',
    ],
  };
  private clientes: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private estado: IFiltroTabla = {
    elementos: [
      { nombre: 'Activos', _id: 'true' },
      { nombre: 'Inactivos', _id: 'false' },
    ],
    filter: {
      field: 'activo',
      value: true,
    },
    label: 'Estado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private appActivada: IFiltroTabla = {
    elementos: [
      { nombre: 'APP Activada', _id: { $exists: true } },
      { nombre: 'APP No Activada', _id: { $exists: false } },
    ],
    filter: {
      field: 'ultimoAcceso',
    },
    label: 'App Activada',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private modoRegistro: IFiltroTabla = {
    elementos: [
      { nombre: 'Importado', _id: [{ importado: true }] },
      {
        nombre: 'Creado por Admin',
        _id: [
          { creadoPorAdmin: true },
          {
            $or: [{ importado: false }, { importado: { $exists: false } }],
          },
        ],
      },
      {
        nombre: 'Creado por Vecino (DNI)',
        _id: [{ creadoPorAdmin: false }, { dniEscaneado: true }],
      },
      {
        nombre: 'Creado por Vecino (Manual)',
        _id: [{ creadoPorAdmin: false }, { dniEscaneado: false }],
      },
    ],
    filter: {
      field: '$and',
    },
    label: 'Modo de Registro',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private notificaciones: IFiltroTabla = {
    elementos: [
      { label: 'Aceptadas', value: { $exists: true } },
      { label: 'Rechazadas', value: { $exists: false } },
    ],
    filter: {
      field: 'tokenPush',
    },
    label: 'Notificaciones',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  private aplicacion: IFiltroTabla = {
    elementos: [
      { label: 'Aplicación', value: { $in: ['ios', 'android', 'web'] } },
      { label: 'Control RF', value: 'rf' },
      { label: 'Android', value: 'android' },
      { label: 'iOS', value: 'ios' },
      { label: 'Web', value: 'web' },
    ],
    filter: {
      field: 'appType',
    },
    label: 'Aplicación',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };

  public filtros: IFiltroTabla[] = [
    this.clientes,
    this.estado,
    this.appActivada,
    this.modoRegistro,
    this.notificaciones,
    this.aplicacion,
  ];

  public loading = true;
  public columnas = [
    'fechaCreacion',
    'idCliente',
    'modoRegistro',
    'ultimoAcceso',
    'dni',
    'nombre',
    'telefono',
    'email',
    'envioCodigo',
    'direccion',
    'complementoDireccion',
    'tokenPush',
    'app',
    'acciones',
  ];
  public nombreColumnas = [
    'Fecha de Registro',
    'Modo de Registro',
    'Cliente',
    'Activo',
    'DNI',
    'Nombre',
    'Teléfono',
    'Email',
    'Codigo de Vecino',
    'Dirección',
    'Complemento de Dirección',
    'Notificaciones',
    'App',
    'Acciones',
  ];
  public saveColumnas = 'tabla-vecinos-2';
  public dataSource = new MatTableDataSource<IConfigVecino>([]);
  public datos: IConfigVecino[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public totalCount = 0;
  private initialFilters: IFilter<IConfigVecino> = {
    activo: true,
  };

  private populate: IPopulate = [
    { path: 'cliente', select: 'nombre' },
    { path: 'categoria.categoria', select: 'nombre' },
  ];

  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    filter: JSON.stringify(this.initialFilters),
  };
  // Mobile query
  public mobileQuery: MediaQueryList;
  //
  // Listado Continuo
  private datos$?: Subscription;
  private clientes$?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private listadosService: ListadosService,
    public helper: HelperService,
    public vecinosService: VecinosService,
    private dialogService: DialogService,
    public matDialog: MatDialog,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################

  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<IConfigVecino>): Promise<void> {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  private syncPaginators() {
    this.top?.page.subscribe((event: PageEvent) => {
      if (this.bottom) {
        this.bottom.length = event.length;
        this.bottom.pageIndex = event.pageIndex;
        this.bottom.pageSize = event.pageSize;
      }
    });

    this.bottom?.page.subscribe((event: PageEvent) => {
      if (this.top) {
        this.top.length = event.length;
        this.top.pageIndex = event.pageIndex;
        this.top.pageSize = event.pageSize;
      }
    });
  }

  //

  public async exportar(): Promise<void> {
    const cliente = (this.clientes.elementos as ICliente[])?.find(
      (c) => c._id === this.clientes.filter.value,
    )?.nombre;
    const mensaje = `¿Desea exportar los ${this.totalCount} vecinos${
      cliente ? ` de ${cliente}` : ''
    }?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.vecinosService.exportar(this.queryParams);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes.elementos = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listar() {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IConfigVecino>>('configVecinos', this.queryParams)
      .subscribe((data) => {
        this.datos = data.datos;
        this.totalCount = data.totalCount;
        console.log(
          new Date().toLocaleString(),
          `listado de configVecinos`,
          data,
        );
      });
    await this.listadosService.getLastValue('configVecinos', this.queryParams);
  }

  public async eliminar(dato: IConfigVecino): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el vecino ${dato.datosPersonales?.nombre}? El mismo se podrá volver a registrar`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await firstValueFrom(this.vecinosService.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  public async desloguear(dato: IConfigVecino): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desloguear al vecino ${dato.datosPersonales?.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await firstValueFrom(
          this.vecinosService.desloguear(dato.datosPersonales!.telefono!),
        );
        this.helper.notifSuccess('Logout correcto');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  public async editar(data: IConfigVecino): Promise<void> {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarVecinoComponent, config);
  }

  private reproducirSonido(totalCount: number): void {
    if (this.godMode) {
      if (this.totalCount && totalCount > this.totalCount) {
        this.audio = new Audio('/assets/sonidos/caja.mp3');
        this.audio?.play();
      }
    }
  }

  public getModoRegistro(vecino: IVecino) {
    if (vecino.creadoPorAdmin && !vecino.importado) {
      return 'Creado por Admin';
    }
    if (vecino.importado) {
      return 'Importado';
    }
    if (!vecino.creadoPorAdmin) {
      if (vecino.dniEscaneado) {
        return 'Creado por Vecino (DNI)';
      } else if (vecino.dniEscaneado === false) {
        return 'Creado por Vecino (Manual)';
      } else {
        return 'Creado por Vecino';
      }
    }
    return 'Desconocido';
  }

  private initListener() {
    document.addEventListener('keydown', async (e) => {
      if (e.ctrlKey && e.altKey && e.keyCode === 80) {
        if (!this.godMode) {
          this.audio = new Audio('/assets/sonidos/uololo.mp3');
          this.audio.play();
          await this.dialogService.dialog(
            `MODO DIOS`,
            'Usted ha entrado en modo dios',
          );
          this.godMode = true;
        } else {
          await this.dialogService.dialog(
            `MODO DIOS`,
            'Usted ha salido del modo dios',
          );
          this.godMode = false;
        }
      }
    });
  }

  private stopListener() {
    document.removeEventListener('keydown', () => {});
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar(), this.listarClientes()]);

    this.initListener();
    this.loading = false;
  }

  ngAfterViewInit(): void {
    this.syncPaginators();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.stopListener();
  }
}
