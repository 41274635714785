import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//
import { LoginModule } from './entidades/login/login.module';
import { MenuModule } from './entidades/menu/menu.module';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () => LoginModule,
  },
  {
    path: 'main',
    // canActivate: [LoginGuard],
    loadChildren: () => MenuModule,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
