<div class="table-container mat-elevation-z2 mx-1 mt-4">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton XLS -->
    <button mat-fab matTooltip="Exportar" color="primary" (click)="exportar()">
      XLS
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna iccid  -->
    <ng-container matColumnDef="iccid">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        ICC ID
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">ICC ID:</span>
        {{ row.iccid }}
      </mat-cell>
    </ng-container>

    <!-- Columna imsi  -->
    <ng-container matColumnDef="imsi">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        IMSI
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">IMSI:</span>
        {{ row.imsi }}
      </mat-cell>
    </ng-container>

    <!-- Columna ChipID  -->
    <ng-container matColumnDef="chipId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Chip ID
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Chip ID:</span>
        {{ chipId(row.iccid) }}
      </mat-cell>
    </ng-container>

    <!-- Columna operador  -->
    <ng-container matColumnDef="operador">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Operador
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Operador:</span>
        {{ row.operador }}
      </mat-cell>
    </ng-container>

    <!-- Columna apn  -->
    <ng-container matColumnDef="apn">
      <mat-header-cell *matHeaderCellDef mat-sort-header> APN </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">APN:</span>
        {{ row.apn }}
      </mat-cell>
    </ng-container>

    <!-- Columna numero  -->
    <ng-container matColumnDef="numero">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Número
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Número:</span>
        {{ row.numero }}
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <button
          class="mx-1 no-shadow"
          mat-mini-fab
          color="accent"
          matTooltip="Editar"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <!-- <button
          class="mx-1 no-shadow"
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button> -->
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
