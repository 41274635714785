<div class="table-container mat-elevation-z2 mx-1 mt-4">
  <!-- Paginator -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha  -->
    <ng-container matColumnDef="fechaEncendido">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha de encendido
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha de encendido:</span>
        {{ row.fechaEncendido | date : "short" }}
      </mat-cell>
    </ng-container>

    <!-- Columna vecino  -->
    <ng-container matColumnDef="vecino">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Vecino
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Vecino:</span>
        {{ row.vecino?.nombre || "Vecino no encontrado" }}
      </mat-cell>
    </ng-container>

    <!-- Columna tipo  -->
    <ng-container matColumnDef="tipo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tipo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Tipo:</span>
        {{ row.tipo }}
      </mat-cell>
    </ng-container>

    <!-- Columna origen  -->
    <ng-container matColumnDef="origen">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Origen
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Origen:</span>
        {{ row.origen | titlecase }}
      </mat-cell>
    </ng-container>

    <!-- Columna motivo  -->
    <ng-container matColumnDef="motivo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Motivo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Motivo:</span>
        {{ row.motivo | titlecase }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>

  <!-- Paginator -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>
