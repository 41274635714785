import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICliente,
  IConfigVecino,
  IControl,
  ICrearControl,
  IFilter,
  IListado,
  IQueryParam,
  ISirena,
  ISocketMessage,
} from 'modelos/src';
import { firstValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { WebSocketService } from '../../../auxiliares/servicios/websocket';
import { ControlesService } from '../controles.service';

@Component({
  selector: 'app-crear-editar-controles',
  templateUrl: './crear-editar-controles.component.html',
  styleUrls: ['./crear-editar-controles.component.scss'],
})
export class CrearEditarControlesComponent implements OnInit, OnDestroy {
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;
  hide = true;

  clientes: ICliente[] = [];

  configs: IConfigVecino[] = [];
  configs$?: Observable<IConfigVecino[]>;
  inputConfigs$ = new Subject<string>();

  sirenas: ISirena[] = [];
  sirenas$?: Observable<ISirena[]>;
  inputSirenas$ = new Subject<string>();

  private clientes$?: Subscription;
  private wsSubscription?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IControl,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarControlesComponent>,
    private service: ControlesService,
    private helper: HelperService,
    private listadosService: ListadosService,
    private webSocketService: WebSocketService,
  ) {}

  private crearFormulario(): void {
    this.title = this.data?._id ? 'Editar Control' : 'Crear Control';
    this.formulario = this.fb.group({
      chipIdSirena: [],
      etiqueta: [this.data?.etiqueta],
      chipId: [
        this.data?.chipId,
        [Validators.required, Validators.minLength(4)],
      ],
      idCliente: [this.data?.idCliente],
      idConfigVecino: [this.data?.idConfigVecino],
      incrementar: [false],
    });

    if (this.data?.configVecino) {
      this.configs = [this.data.configVecino];
      this.configs$ = of([this.data.configVecino]);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getData(): ICrearControl {
    const data: ICrearControl = {
      chipId: this.formulario?.get('chipId')?.value,
      idCliente: this.formulario?.get('idCliente')?.value,
      idConfigVecino: this.formulario?.get('idConfigVecino')?.value,
      etiqueta: this.formulario?.get('etiqueta')?.value,
    };
    // if (!data.idVecino) {
    //   delete data.idVecino;
    // }
    return data;
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      console.log('data', data);
      if (this.data) {
        await firstValueFrom(this.service.editar(this.data._id!, data));
        this.helper.notifSuccess('Editado correctamente');
        this.dialogRef.close(true);
      } else {
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
        //
        this.formulario?.patchValue({ chipId: null });
        this.formulario?.patchValue({ idVecino: null });
        // Incremento de etiqueta como string
        const incrementar = this.formulario?.get('incrementar')?.value;
        if (data.etiqueta && incrementar) {
          const len = data.etiqueta?.length;
          const etNum = +data.etiqueta + 1;
          let etiqueta = `0000000000000${etNum}`;
          etiqueta = etiqueta.substring(etiqueta.length - len);
          this.formulario?.patchValue({ etiqueta });
        } else {
          this.formulario?.patchValue({ etiqueta: null });
        }
        //
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  // Listados

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private crearFiltroConfig(search: string): IQueryParam {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filtro: IFilter<any> = {};
    if (search) {
      filtro.$or = [
        { 'datosPersonales.dni': { $regex: search, $options: 'i' } },
        { 'datosPersonales.nombre': { $regex: search, $options: 'i' } },
      ];
    }
    const idCliente = this.formulario?.get('idCliente')?.value;
    if (idCliente) {
      filtro.idCliente = idCliente;
    }

    const populate = {
      path: 'cliente',
      select: 'nombre',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filtro),
      limit: 10,
      populate: JSON.stringify(populate),
      select: 'datosPersonales idCliente cliente idVecino',
    };
    return query;
  }

  private crearFiltroSirena(search: string): IQueryParam {
    const filtro: IFilter<ISirena> = {};
    if (search) {
      filtro.$or = [
        { chipId: { $regex: search, $options: 'i' } },
        { direccionGps: { $regex: search, $options: 'i' } },
        { direccionManual: { $regex: search, $options: 'i' } },
      ];
    }
    const query: IQueryParam = {
      filter: JSON.stringify(filtro),
      limit: 10,
      select: 'chipId direccionGps direccionManual',
    };
    return query;
  }

  onSearchConfigs() {
    this.configs$ = of([]);
    this.inputConfigs$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) =>
          this.listadosService.listarConfigVecinos$(
            this.crearFiltroConfig(term),
          ),
        ),
      )
      .subscribe((data) => {
        console.log('configs encontradas', data);
        this.configs = data;
        this.configs$ = of(data);
      });
  }

  onSearchSirenas() {
    this.sirenas$ = of([]);
    this.inputSirenas$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) =>
          this.listadosService.listarSirenas$(this.crearFiltroSirena(term)),
        ),
      )
      .subscribe((data) => {
        console.log('sirenas encontradas', data);
        this.sirenas = data;
        this.sirenas$ = of(data);
      });
  }

  // ################################################################################
  // ################################# SUSCRIBE #####################################
  // ################################################################################

  private suscribeWsUpdates() {
    this.wsSubscription = this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }

  private handleUpdateResponse(message: ISocketMessage) {
    if (!this.data) {
      if (message.paths?.includes('controlnoconocido')) {
        const body: {
          chipIdControl: string;
          chipIdSirena: string;
          etiqueta: string;
        } =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          message.body as any;
        const sirenaElegida = this.formulario?.get('chipIdSirena')?.value;
        const etiqueta = body.etiqueta;
        const chipId = body.chipIdControl;
        if (sirenaElegida === body.chipIdSirena) {
          if (etiqueta) {
            this.helper.notifWarn(
              `Control chipId ${chipId} cargado con la etiqueta ${etiqueta}`,
            );
          } else {
            this.formulario?.patchValue({ chipId });
          }
        }
      }
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.crearFormulario();
    this.suscribeWsUpdates();
    await this.listarClientes();
    this.onSearchConfigs();
    this.onSearchSirenas();
    console.log('this.data', this.data);

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.wsSubscription?.unsubscribe();
  }
}
