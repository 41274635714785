import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiAdminService } from 'src/app/auxiliares/servicios/http-api.service';
import { HelperService } from '../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class MensajesService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public enviarMensaje(dato: Mensaje): Observable<void> {
    return this.http.post(`/mensajes/enviarMensaje`, dato);
  }

  public enviarMensajeWhatsappTemaplte(
    dato: MensajeWhatsAppTemplate,
  ): Observable<void> {
    return this.http.post(`/mensajes/enviar-whatsapp-template`, dato);
  }
}

export interface Mensaje {
  pais?: string;
  telefono?: string;
  mensaje: string;
  servicio: 'SMS' | 'WHATSAPP';
  masivo: boolean;
}

export interface MensajeWhatsAppTemplate {
  pais?: string;
  telefono?: string;
  servicio: 'SMS' | 'WHATSAPP';
  messagingServiceSid: string;
  contentSid: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentVariables?: Record<string | number, any>;
}
