/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  ICrearSirena,
  IListado,
  IQueryParam,
  ISirena,
  IUpdateSirena,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class SirenasService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ISirena>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/sirenas`, { params });
  }

  public listarPorId(id: string): Observable<ISirena> {
    return this.http.get(`/sirenas/${id}`);
  }

  public crear(dato: ICrearSirena): Observable<ISirena> {
    return this.http.post(`/sirenas`, dato);
  }

  public bulkCrear(datos: ICrearSirena[]) {
    return this.http.post(`/sirenas/bulk`, datos);
  }

  public upsertMany(datos: ICrearSirena[]) {
    return this.http.post(`/sirenas/upsertMany`, datos);
  }

  public editar(id: string, dato: IUpdateSirena): Observable<ISirena> {
    return this.http.put(`/sirenas/${id}`, dato);
  }

  public eliminar(id: string): Observable<ISirena> {
    return this.http.delete(`/sirenas/${id}`);
  }

  public actualizarFirmware(chipId: string): Observable<void> {
    return this.http.post(`/comandos/actualizarFirmware`, { chipId });
  }

  public reset(chipId: string): Observable<void> {
    return this.http.post(`/comandos/reset`, { chipId });
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/sirenas/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'export.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }
}
