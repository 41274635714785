import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IBoton,
  ICategoria,
  ICreateCategoria,
  IListado,
  IQueryParam,
  TipoBoton,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CategoriasService } from '../categorias.service';

@Component({
  selector: 'app-crear-editar-categorias',
  templateUrl: './crear-editar-categorias.component.html',
  styleUrls: ['./crear-editar-categorias.component.scss'],
})
export class CrearEditarCategoriasComponent implements OnInit {
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;

  public botones: IBoton[] = [];

  public id: string | null = null;
  public data?: ICategoria;
  public idsPrincipalesSelecionados: string[] = [];
  public idsSecundariosSelecionados: string[] = [];
  public idsOtrosSelecionados: string[] = [];
  public idBotonAlertaControl?: string;

  public acciones: TipoBoton[] = ['Reflector', 'Sirena', 'Alerta'];
  public boton1?: string;
  public boton2?: string;
  public boton3?: string;

  // Listado Continuo
  private datos$?: Subscription;
  private botones$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private service: CategoriasService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private listadosService: ListadosService,
  ) {}

  // Drag and Drop
  public dropPrincipales(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.idsPrincipalesSelecionados,
      event.previousIndex,
      event.currentIndex,
    );
  }
  public dropSecundarios(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.idsSecundariosSelecionados,
      event.previousIndex,
      event.currentIndex,
    );
  }
  public dropOtros(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.idsOtrosSelecionados,
      event.previousIndex,
      event.currentIndex,
    );
  }

  public btnName(id: string): string {
    const btn = this.botones.find((d) => d._id === id);
    return `${btn?.nombre} (${btn?.variante})`;
  }

  //

  private crearFormulario(): void {
    this.formulario = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      variante: [this.data?.variante],
      prioridad: [this.data?.prioridad],
      color: [this.data?.color],
      twitter: [this.data?.twitter],
      tercerBoton: [this.data?.tercerBoton],
      nombreTemplate: [this.data?.nombreTemplate],
      idsBtnsPrincipales: [this.data?.btnsPrincipales?.map((d) => d._id)] || [],
      idsBtnsSecundarios: [this.data?.btnsSecundarios?.map((d) => d._id)] || [],
      idsOtrosBotones: [this.data?.otrosBotones?.map((d) => d._id)] || [],
      idBotonAlertaControl: [this.data?.idBotonAlertaControl],
      boton1: [this.data?.boton1],
      boton2: [this.data?.boton2],
      boton3: [this.data?.boton3],
    });
  }

  public volver(): void {
    window.history.back();
  }

  public searchBoton = (term: string, item: IBoton) => {
    if (item.nombre?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.variante?.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };

  //

  private getData(): ICreateCategoria {
    return {
      nombre: this.formulario?.get('nombre')?.value,
      variante: this.formulario?.get('variante')?.value,
      color: this.formulario?.get('color')?.value,
      prioridad: this.formulario?.get('prioridad')?.value,
      idsBtnsPrincipales: this.idsPrincipalesSelecionados,
      idsBtnsSecundarios: this.idsSecundariosSelecionados,
      idsOtrosBotones: this.idsOtrosSelecionados,
      idBotonAlertaControl: this.formulario?.get('idBotonAlertaControl')?.value,
      twitter: this.formulario?.get('twitter')?.value || false,
      tercerBoton: this.formulario?.get('tercerBoton')?.value || false,
      nombreTemplate: this.formulario?.get('nombreTemplate')?.value || false,
      boton1: this.formulario?.get('boton1')?.value,
      boton2: this.formulario?.get('boton2')?.value,
      boton3: this.formulario?.get('boton3')?.value,
    };
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //
  private async listar(id?: string): Promise<void> {
    if (id) {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<ICategoria>('categoria', id)
        .subscribe((data) => {
          this.data = data;
          console.log(
            new Date().toLocaleString(),
            `listado de categoria`,
            data,
          );
        });
      await this.listadosService.getLastValue('categoria', id);
    }
  }

  private async listarBotones(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      limit: 0,
    };

    this.botones$?.unsubscribe();
    this.botones$ = this.listadosService
      .subscribe<IListado<IBoton>>('botones', query)
      .subscribe((data) => {
        this.botones = data.datos;
        console.debug(new Date().toLocaleString(), `listado de botones`, data);
      });
    await this.listadosService.getLastValue('botones', query);
  }

  public updateBoton(number: number, value: string) {
    if (value === 'Sirena' || value === 'Reflector') {
      if (number === 1) {
        this.boton1 = value;
        this.formulario?.patchValue({ boton1: value });
      }
      if (number === 2) {
        this.boton2 = value;
        this.formulario?.patchValue({ boton2: value });
      }
      if (number === 3) {
        this.boton3 = value;
        this.formulario?.patchValue({ boton3: value });
      }
    } else {
      if (number === 1) {
        this.boton1 = value;
        this.formulario?.patchValue({ boton1: '' });
      }
      if (number === 2) {
        this.boton2 = value;
        this.formulario?.patchValue({ boton2: '' });
      }
      if (number === 3) {
        this.boton3 = value;
        this.formulario?.patchValue({ boton3: '' });
      }
    }
  }

  public cargarConfigControles() {
    if (!this.id) return;
    const bot1 = this.formulario?.get('boton1')?.value;

    if (bot1 === 'Reflector' || bot1 === 'Sirena') {
      this.boton1 = bot1;
    } else if (bot1 === null || bot1 === undefined) {
      this.boton1 = undefined;
    } else {
      this.boton1 = 'Alerta';
    }

    const bot2 = this.formulario?.get('boton2')?.value;
    if (bot2 === 'Reflector' || bot2 === 'Sirena') {
      this.boton2 = bot2;
    } else if (bot2 === null || bot2 === undefined) {
      this.boton2 = undefined;
    } else {
      this.boton2 = 'Alerta';
    }

    const bot3 = this.formulario?.get('boton3')?.value;
    if (bot3 === 'Reflector' || bot3 === 'Sirena') {
      this.boton3 = bot3;
    } else if (bot3 === null || bot3 === undefined) {
      this.boton3 = undefined;
    } else {
      this.boton3 = 'Alerta';
    }
  }

  public onColorChange(event: string, control: string) {
    this.formulario?.get(control)?.patchValue(event);
  }

  //

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    if (this.id) {
      await this.listar(this.id);
    }
    this.listarBotones();
    this.crearFormulario();
    this.cargarConfigControles();
    if (this.data?.btnsPrincipales) {
      this.idsPrincipalesSelecionados =
        this.formulario?.get('idsBtnsPrincipales')?.value;
    }
    if (this.data?.btnsSecundarios) {
      this.idsSecundariosSelecionados =
        this.formulario?.get('idsBtnsSecundarios')?.value;
    }
    if (this.data?.otrosBotones) {
      this.idsOtrosSelecionados =
        this.formulario?.get('idsOtrosBotones')?.value;
    }
  }
}
