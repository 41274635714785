<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Categorías </span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" [routerLink]="'crear'">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna nombre  -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre:</span>
        {{ row.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna nombre  -->
    <ng-container matColumnDef="nombreTemplate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre Template
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre Template:</span>
        {{ row.nombreTemplate }}
      </mat-cell>
    </ng-container>

    <!-- Columna variante  -->
    <ng-container matColumnDef="variante">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Variante
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Variante:</span>
        {{ row.variante }}
      </mat-cell>
    </ng-container>

    <!-- Columna prioridad  -->
    <ng-container matColumnDef="prioridad">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Prioridad
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Prioridad:</span>
        {{ row.prioridad }}
      </mat-cell>
    </ng-container>

    <!-- Columna twitter  -->
    <ng-container matColumnDef="twitter">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Twitter
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Twitter:</span>
        <mat-icon *ngIf="row.twitter">done</mat-icon>
        <mat-icon *ngIf="!row.twitter">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna color  -->
    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Color
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Color:</span>
        <div
          class="color-container"
          [style.background]="row.color"
          [style.color]="helper.invertColor(row.color)"
        >
          ALERTA
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna btnsPrincipales  -->
    <ng-container matColumnDef="btnsPrincipales">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Btns. Principales
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Btns. Principales:</span>
        <div class="p-2">
          <p class="m-0" *ngFor="let boton of row.btnsPrincipales">
            {{ boton.nombre }}
            {{ boton.variante ? "(" + boton.variante + ")" : "" }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna btnsSecundarios  -->
    <ng-container matColumnDef="btnsSecundarios">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Btns. Secundarios
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Btns. Secundarios:</span>
        <div class="p-2">
          <p class="m-0" *ngFor="let boton of row.btnsSecundarios">
            {{ boton.nombre }}
            {{ boton.variante ? "(" + boton.variante + ")" : "" }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Otros Botones  -->
    <ng-container matColumnDef="otrosBotones">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Otros Botones
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Otros Botones:</span>
        <div class="p-2">
          <p class="m-0" *ngFor="let boton of row.otrosBotones">
            {{ boton.nombre }}
            {{ boton.variante ? "(" + boton.variante + ")" : "" }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna botonAlertaControl  -->
    <ng-container matColumnDef="botonAlertaControl">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Control RF
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Control RF:</span>
        <span>
          {{ row.botonAlertaControl?.nombre || row.idBotonAlertaControl }}
          {{
            row.botonAlertaControl?.variante
              ? "(" + row.botonAlertaControl.variante + ")"
              : ""
          }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna botonesRf  -->
    <ng-container matColumnDef="botonesRf">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Botones RF
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Botones RF:</span>
        <div class="column-center">
          @if (row.boton1) {
            <div class="boton-rf">
              <span>{{ parseBotonRf(row.boton1) }}</span>
            </div>
          } @else {
            <div class="boton-rf">
              <span class="no-info">Sin Configurar</span>
            </div>
          }
          @if (row.boton2) {
            <div class="boton-rf">
              <span>{{ parseBotonRf(row.boton2) }}</span>
            </div>
          } @else {
            <div class="boton-rf">
              <span class="no-info">Sin Configurar</span>
            </div>
          }
          @if (row.boton3) {
            <div class="boton-rf">
              <span>{{ parseBotonRf(row.boton3) }}</span>
            </div>
          } @else {
            <div class="boton-rf">
              <span class="no-info">Sin Configurar</span>
            </div>
          }
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <button
          *ngIf="row.editable"
          class="mx-1 no-shadow"
          mat-mini-fab
          color="primary"
          matTooltip="Clonar"
          (click)="clonar(row)"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          class="mx-1 no-shadow"
          mat-mini-fab
          color="accent"
          matTooltip="Editar"
          [routerLink]="['editar', row._id]"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="row.editable"
          class="mx-1 no-shadow"
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
