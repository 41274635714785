<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="form"
      [formGroup]="formulario"
      (ngSubmit)="enviar()"
      fxLayout="column"
    >
      <mat-checkbox *ngIf="data" class="mb-3" formControlName="activo">
        Habilitado
      </mat-checkbox>

      <mat-form-field>
        <mat-label>Documento</mat-label>
        <input matInput formControlName="dni" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Sexo</mat-label>
        <mat-select formControlName="sexo" required>
          <mat-option [value]="true">Masculino</mat-option>
          <mat-option [value]="false">Femenino</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48">
        <mat-label>Fecha de Nacimiento</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="fechaNacimiento"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Teléfono</mat-label>
        <input
          class="telefono"
          type="tel"
          matInput
          placeholder="11 60606060"
          formControlName="telefono"
          required
        />
        <mat-hint style="color: black"
          >Ingrese su número de teléfono sin el 0 ni el 15</mat-hint
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Correo Electrónico</mat-label>
        <input
          type="email"
          matInput
          placeholder="Correo Electrónico"
          formControlName="email"
          required
        />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="close()">Volver</button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
