import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IActualizacionFirmware,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CrearFirmwareComponent } from '../crear-firmware/crear-firmware.component';
import { FirmwareSirenasService } from '../firmware-sirenas.service';

declare type mainType = IActualizacionFirmware;

@Component({
  selector: 'app-listado-actualizacion-firm',
  templateUrl: './listado-actualizacion-firm.component.html',
  styleUrls: ['./listado-actualizacion-firm.component.scss'],
})
export class ListadoActualizacionFirmComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['version', 'url'],
  };

  @Input() loading?: boolean;
  @Input() chipId?: string | null;
  @Output() loadingChange = new EventEmitter<boolean>();

  public columnas = ['fecha', 'firmwareActual', 'firmwareNuevo', 'etapa'];
  public nombreColumnas = [
    'Fecha',
    'Firmware Actual',
    'Firmware Nuevo',
    'Etapa',
  ];
  public saveColumnas = 'tabla-firmware-sirenas';
  public dataSource = new MatTableDataSource<mainType>([]);
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: FirmwareSirenasService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  private onLoadingChange(loading: boolean): void {
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<any>): Promise<void> {
    this.onLoadingChange(true);
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.onLoadingChange(false);
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearFirmwareComponent, config);
  }

  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el firmware?`
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.onLoadingChange(true);
    await this.listar();
    this.onLoadingChange(false);
  }

  private async listar(): Promise<void> {
    // Update Filtro
    const filter: IFilter<IActualizacionFirmware> = {
      chipIdSirena: this.chipId,
    };
    this.queryParams.filter = JSON.stringify(filter);
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IActualizacionFirmware>>(
        'actualizacionFirmwares',
        this.queryParams
      )
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de actualizacionesFirm`,
          data
        );
      });
    await this.listadosService.getLastValue(
      'actualizacionFirmwares',
      this.queryParams
    );
  }

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
