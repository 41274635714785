import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolygonDrawComponent } from './polygon-draw.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { MaterialModule } from '../material.module';
import { PolygonosComponent } from './polygonos/polygonos.component';

@NgModule({
  declarations: [
    PolygonDrawComponent,
    PolygonosComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MaterialModule
  ],
  exports: [
    PolygonDrawComponent
  ]
})
export class PolygonDrawModule { }
