@if (loading() === true) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<h2 mat-dialog-title>Importar Sirenas</h2>
<mat-dialog-content>
  @if (form) {
    <div class="row-space-between">
      <input
        #input
        style="position: fixed; top: -20000px"
        type="file"
        accept=".csv"
        (change)="cargarCsv($event)"
        class="input"
      />
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="input.click()"
      >
        <span>Cargar CSV</span>
        <mat-icon>upload_file</mat-icon>
      </button>

      @if (loading() === true) {
        <mat-progress-spinner
          color="accent"
          mode="indeterminate"
          diameter="30"
        ></mat-progress-spinner>
      }

      @if (loading() === false && chipIds() > 0) {
        <mat-icon tooltip="{{ chipIds() }}" color="accent">done</mat-icon>
      }

      @if (loading() === false && chipIds() === 0) {
        <mat-icon tooltip="{{ chipIds() }}" color="warn">close</mat-icon>
      }
    </div>
    <br />
    <form [formGroup]="form" style="gap: 8px">
      <mat-form-field>
        <mat-label>Fecha de Fabricación</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          (dateChange)="onDateChange($event)"
          readonly
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Versión</mat-label>
        <input matInput formControlName="version" />
      </mat-form-field>

      <mat-checkbox formControlName="fuenteExterna"
        >¿Tiene fuente externa?</mat-checkbox
      >
    </form>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClose()" color="warn" type="button">
    Cancelar
  </button>
  <button
    mat-raised-button
    (click)="onSubmit()"
    cdkFocusInitial
    color="accent"
    type="button"
    [disabled]="loading() === true || chipIds() === 0 || form?.invalid"
  >
    Importar
  </button>
</mat-dialog-actions>
