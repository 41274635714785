import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Rol } from 'modelos/src';
import { AuthService } from '../entidades/login/auth.service';
import { HelperService } from './servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard  {
  constructor(
    private helperService: HelperService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.isLogged()) {
      const rolesPermitidos = route.data.roles as Rol[];
      const rolesUsuario = this.authService.getUsuario()?.roles;
      const clienteAdmin = this.authService.getUsuario()?.cliente?.admin;
      if (clienteAdmin) {
        if (
          !rolesPermitidos?.length ||
          rolesPermitidos.some((rol) => rolesUsuario?.includes(rol))
        ) {
          return true;
        } else {
          this.helperService.notifWarn('Permiso de rol denegado');
          return this.router.parseUrl('/main');
        }
      } else {
        this.helperService.notifWarn('Permiso de cliente denegado');
        return this.router.parseUrl('/login');
      }
    } else {
      this.helperService.notifWarn('Debe iniciar sesión');
      return this.router.parseUrl('/login');
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    if (this.authService.isLogged()) {
      const rolesPermitidos = route.data.roles as Rol[];
      const rolesUsuario = this.authService.getUsuario()?.roles;
      const clienteAdmin = this.authService.getUsuario()?.cliente?.admin;
      if (clienteAdmin) {
        if (
          !rolesPermitidos?.length ||
          rolesPermitidos.some((rol) => rolesUsuario?.includes(rol))
        ) {
          return true;
        } else {
          this.helperService.notifWarn('Permiso de rol denegado');
          return this.router.parseUrl('/main');
        }
      } else {
        this.helperService.notifWarn('Permiso de cliente denegado');
        return this.router.parseUrl('/login');
      }
    } else {
      this.helperService.notifWarn('Debe iniciar sesión');
      return this.router.parseUrl('/login');
    }
  }
}
